export const getPackagePrice = (packages, packageName, commitment) => {
  return packages[packageName].prices.find((p) => p.commitment === commitment).price;
}

export const calculatePackageAddonsPrice = (packages, packageName, addons, type) => {
  let total = 0;
  Object.entries(addons).forEach(([key, value]) => {
    if (value === false) { return; }

    const getPackageAddon = packages[packageName].addons.find((p) => p.name === key && p.type === type)
    if (getPackageAddon) {
      total += getPackageAddon.price;
    }
  });
  return total;
}

export const getPackageAddonPrice = (packages, packageName, packageAddon) => {
  const addon = packages[packageName].addons.find((a) => a.name === packageAddon);
  if (addon) {
    return addon.price;
  } else {
    return 0; // return 0 if addon is not found
  }
}

export const getPackageAddonMandatory = (packages, packageName, packageAddon) => {
  const addon = packages[packageName].addons.find((a) => a.name === packageAddon);
  if (addon) {
    return addon.mandatory;
  } else {
    return false; // return false if addon is not found
  }
}
 export const getSignUpPackageAddons = (packages, packageName) => {
  const addons = packages[packageName].addons.filter((a) => a.type === "signup");
  return addons;
 }

 export const getPackageDisplayName = (packages, packageName) => {
  return packages[packageName].display;
 }

 export const getIncludedPackageAddons = (packages, packageName) => {
  const addons = packages[packageName].addons.filter((a) => a.type === "monthly" && a.price === 0);
  return addons;
 }
