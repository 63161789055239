import React, {
  useMemo,
} from 'react';
import CustomSwitch from "../components/CustomSwitch";
import ObservedVisitRoute from "../components/ObservedVisitRoute";
import OuterWizardContainer from "../components/OuterWizardContainer";
import PackagePriceContainer from "../components/PackagePriceContainer";
import Wizard from "../components/Wizard";
import { useWizardActions, useWizardState, useWizardProps } from "../state";
import { InitHeader } from "./headers";
import { getPackagePrice } from '../state/packages';
import CommitmentButton from "../components/CommitmentButton";


const CommitmentToggle = () => {
  const { commitment } = useWizardState();
  const { setCommitment } = useWizardActions();

  return (
    <div style={{ textAlign: "center", marginTop: "3em" }}>
      <div style={{ backgroundColor: "#a5c9ff", display: "inline-block", borderRadius: "6px" }}>
        <CommitmentButton
          onClick={() => setCommitment(6)}
          selected={commitment === 6}>
          6 Months
        </CommitmentButton>
        <CommitmentButton
          onClick={() => setCommitment(12)}
          selected={commitment === 12}>
          12 Months
        </CommitmentButton>
      </div>
    </div>
  );
};

const packageList = () => {
  const { packages } = useWizardProps();
  const { commitment } = useWizardState();

  const sortedPackages = Object.entries(packages).sort((a, b) => {
    const priceA = getPackagePrice(packages, a[0], commitment);
    const priceB = getPackagePrice(packages, b[0], commitment);
    return priceA - priceB;
  });

  return sortedPackages.map(([key, value]) => {
    const packagePrice = getPackagePrice(packages, key, commitment);
    return <PackagePriceContainer packageName={key} price={packagePrice} />;
  });
}

const PackagePicker = () => {
  const { initialCommitment, initialPackage, packages } = useWizardProps();
  const { setCommitment, setBilling, nextStep } = useWizardActions();

  useMemo(() => {
    // remove double quotes
    const cleanInitialPackage = initialPackage.split('"').join('');

    if (cleanInitialPackage && packages[cleanInitialPackage]) {
      if (initialCommitment === "6") {
        setCommitment(6);
      } else {
        setCommitment(12);
      }
      setBilling(cleanInitialPackage, packages);
      nextStep();
      return null;
    }
  }, []);

  return (
    <ObservedVisitRoute>
      <InitHeader />
      <CommitmentToggle />
      <OuterWizardContainer>
        <Wizard column={false} alignItems={"flex-start"}>
          {packageList()}
        </Wizard>
      </OuterWizardContainer>
    </ObservedVisitRoute>
  );
};

export default PackagePicker;
