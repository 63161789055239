import React, {
  useEffect,
  useReducer,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useLocation } from 'wouter';
import {
  getCsrfToken,
  getData,
  mount,
} from '../../util';
import routes from '../steps/routes';

import BoardOptionProvider, {
  useBoardOptionState,
  useBoardOptionPreloader,
  useBoardOptions,
  useBoardById,
} from './board-options';

import MarketOptionProvider, {
  useMarketOptionState,
  useMarketOptionsLoader,
  useMarketOptions,
} from './market-options';

import {
  getPackagePrice,
  calculatePackageAddonsPrice,
  getPackageAddonPrice } from './packages';

const WizardContext = React.createContext();
const WizardDispatchContext = React.createContext();
const WizardPropsContext = React.createContext();


const GOOGLE_PPC = 'google_ppc';
const FACEBOOK_MM = 'facebook_mm';
const TRAFFIC_BLASTER = 'traffic_blaster';

const ADDON_PRICES = {
  [GOOGLE_PPC]: 600,
  [FACEBOOK_MM]: 500,
  [TRAFFIC_BLASTER]: 300,
};

// TODO: receive the signup fee value from the backend and initialize accordingly
const DEFAULT_WIZARD_STATE = {
  existingUser: false,
  currentStep: 0,
  currentPrice: 299,
  addons: {
    [GOOGLE_PPC]: false,
    [FACEBOOK_MM]: false,
    [TRAFFIC_BLASTER]: false,
    geek_ai: false,
    system_setup: true,
    live_training: true,
    solo_training: false,
  },
  packageSignUpFee: 250,
  userDetails: null,
  mlsBoard: null,
  mlsBoardCountry: { value: 'US', label: 'United States' },
  mlsBoardState: null,
  mlsID: '',
  mlsBrokerInfo: false,
  requiresBrokerInfo: false,
  siteInfo: null,
  domainPurchase: null,
  billingPlan: 'monthly',
  commitment: 12,
  currentLocation: '/',
  targetMarket: null,
  visited: routes.reduce((acc, curr, index) => ({
    ...acc,
    [curr]: index === -1,
  }), {}),
};

const getInitialState = () => {
  const parsedState = mount.data('current-state');

  if (!parsedState) {
    return DEFAULT_WIZARD_STATE;
  }

  if (parsedState.expiry) {
    const today = new Date();
    const expiry = new Date(parsedState.expiry);

    if (today < expiry) {
      return parsedState;
    }
  }

  return DEFAULT_WIZARD_STATE;
};

const INITIAL_STATE = getInitialState();

const calculateSelectedAddonPrice = (state) => Object
  .keys(state.addons)
  .reduce(
    (addonPricing, addon) => state.addons[addon] && ADDON_PRICES[addon]
      ? (addonPricing + ADDON_PRICES[addon])
      : addonPricing,
    0);

const wizardReducer = (state, action) => {
  switch (action.type) {
    case 'next-step': {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }

    case 'previous-step': {
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    }

    case 'set-current-location': {
      return {
        ...state,
        currentLocation: action.payload,
      };
    }

    case 'invalidate-visited-before-tos': {
      // NOTE: we sign the tos at the mls step so that should be the last accessible route
      const locationOfMlsStep = routes.indexOf('/mls');

      return {
        ...state,
        visited: Object.keys(state.visited).reduce((newVisited, route) => {
          if (routes.indexOf(route) >= locationOfMlsStep) {
            return {
              ...newVisited,
              [route]: false,
            };
          }

          return {
            ...newVisited,
            [route]: state.visited[route],
          };
        }, {}),
      };

    }

    case 'toggle-addon': {
      const addedOn = state.addons[action.payload];
      let newPrice = state.currentPrice;

      if (state.billingPlan === 'monthly') {
        let addonPrice = calculateSelectedAddonPrice(state);

        if (!addedOn) {
          if (addonPrice !== 0) {
            // In this case, we are adding on a new addon, and the sum of
            // existing addons implies we have all addons enabled, so you get a
            // $100 discount
            addonPrice = addonPrice - 100;
          }

          newPrice = 299 + ADDON_PRICES[action.payload] + addonPrice;
        }

        else {
          newPrice = 299 + addonPrice - ADDON_PRICES[action.payload];
        }
      }


      return {
        ...state,
        addons: {
          ...state.addons,
          [action.payload]: !addedOn,
        },
        currentPrice: newPrice,
      };
    }

    case 'set-details': {
      return {
        ...state,
        userDetails: action.payload,
      };
    }

    case 'set-site-info': {
      return {
        ...state,
        siteInfo: action.payload,
      };
    }

    case 'set-board': {
      return {
        ...state,
        mlsBoard: action.payload,
      };
    }

    case 'set-board-country': {
      return {
        ...state,
        mlsBoardCountry: action.payload,
      };
    }

    case 'set-board-state': {
      return {
        ...state,
        mlsBoardState: action.payload,
      };
    }

    case 'set-mls-id': {
      return {
        ...state,
        mlsID: action.payload,
      };
    }

    case 'set-mls-broker-info': {
      return {
        ...state,
        mlsBrokerInfo: action.payload,
      };
    }

    case 'set-mls-broker-info-required': {
      return {
        ...state,
        requiresBrokerInfo: action.payload,
      };
    }

    case 'set-market': {
      return {
        ...state,
        targetMarket: action.payload,
      };
    }

    case 'set-billing-plan': {
      const packages = action.payload.packages;
      const plan = action.payload.plan;
      let newPrice = state.currentPrice;
      let newPackageSignUpFee = state.packageSignUpFee;

      switch(plan) {
        case 'monthly': {
          // NOTE: when going from yearly back to monthly, we also include the
          // pricing for the selected addons to be billed monthly
          newPrice = 299;
          newPrice += calculateSelectedAddonPrice(state);
          break;
        }

        case 'annual': {
          newPrice = 3289;
          break;
        }

        default: {
          // Calculate package price when switching between packages
          const packagePrice = getPackagePrice(packages, plan, state.commitment);
          const packageAddonsPrice = calculatePackageAddonsPrice(packages, plan, state.addons, "monthly");
          newPrice = packagePrice + packageAddonsPrice;

          // Calculate signup price when switching between packages
          const packageSignupAddonsPrice = calculatePackageAddonsPrice(packages, plan, state.addons, "signup");
          newPackageSignUpFee = packageSignupAddonsPrice;
          break;
        }
      }

      return {
        ...state,
        billingPlan: plan,
        currentPrice: newPrice,
        packageSignUpFee: newPackageSignUpFee,
      };
    }

    case 'set-commitment': {
      return {
        ...state,
        commitment: action.payload
      }
    }

    case 'set-visited': {
      return {
        ...state,
        visited: {
          ...state.visited,
          [action.payload]: true,
        }
      };
    }

    case 'set-promo-code': {
      return {
        ...state,
        promoCode: action.payload
      };
    }

    case 'set-domain-purchase': {
      return {
        ...state,
        domainPurchase: action.payload,
      };
    }

    case 'set-geek-ai': {
      let packages = action.payload.packages;
      let enabled = action.payload.enabled;
      let newPrice = state.currentPrice;
      const price = getPackageAddonPrice(packages, state.billingPlan, "geek_ai");
      if (enabled === true) {
        newPrice += price;
      } else {
        newPrice -= price;
      }

      return {
        ...state,
        currentPrice: newPrice,
        addons: {
          ...state.addons,
          geek_ai: enabled,
        },
      };
    }

    case 'set-system-setup': {
      return {
        ...state,
        addons: {
          ...state.addons,
          systemSetup: action.payload.enabled,
        },
      };
    }

    case 'set-live-training': {
      return {
        ...state,
        addons: {
          ...state.addons,
          liveTraining: action.payload.enablled,
        },
      };
    }

    case 'set-solo-training-sessions': {
      let newPackageSignUpFee = state.packageSignUpFee;
      const packages = action.payload.packages;
      const enabled = action.payload.enabled;
      const price = getPackageAddonPrice(packages, state.billingPlan, "solo_training");
      if (enabled === true) {
        newPackageSignUpFee += price;
      } else {
        newPackageSignUpFee -= price;
      }

      return {
        ...state,
        packageSignUpFee: newPackageSignUpFee,
        addons: {
          ...state.addons,
          solo_training: enabled,
        },
      };
    }

    default: {
      return state;
    }
  }
};



const persistUrl = JSON.parse(getData('persist-url'));

export const WizardProvider = ({ children, ...props }) => {
  const { boardOptionsUrl, marketOptionsUrl } = props;
  const [state, dispatch] = useReducer(wizardReducer, INITIAL_STATE);

  useEffect(() => {
    if (persistUrl) {
      const today = new Date();
      const threeDaysFromToday = new Date();
      threeDaysFromToday.setDate(today.getDate() + 3);

      const storageValue = JSON.stringify({
        ...state,
        expiry: threeDaysFromToday
      });

      $.ajax({
        method: 'POST',
        url: persistUrl,
        data: storageValue,
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
      }).done(() => { }).fail(() => { });
    }
  }, [state]);

  useEffect(() => {
    if (state.userDetails) {
    }
  }, [state.userDetails]);

  return (
    <WizardPropsContext.Provider value={props}>
      <WizardContext.Provider value={state}>
        <WizardDispatchContext.Provider value={dispatch}>
          <BoardOptionProvider boardOptionsUrl={boardOptionsUrl}>
            <MarketOptionProvider marketOptionsUrl={marketOptionsUrl}>
              {children}
            </MarketOptionProvider>
          </BoardOptionProvider>
        </WizardDispatchContext.Provider>
      </WizardContext.Provider>
    </WizardPropsContext.Provider>
  );
};


export const useToggler = (dispatch, payload) => {
  const toggle = useCallback(
    () => dispatch({
      type: 'toggle-addon',
      payload,
    }),

    [dispatch, payload],
  );

  return {
    toggle,
  };
};


export const useWizardActions = () => {
  const dispatch = useContext(WizardDispatchContext);
  const [location, setLocation] = useLocation();
  const nextLocation = useMemo(
    // NOTE: we wrap around when we get to the end just in case to avoid mishaps
    () => routes[(routes.indexOf(location) + 1) % routes.length],
    [location],
  );

  const previousLocation = useMemo(
    () => {
      const currIndex = routes.indexOf(location);

      if (currIndex === 0) {
        return '/';
      }

      return routes[currIndex - 1];
    },
    [location],
  );

  const { toggle: toggleGoogle } = useToggler(
    dispatch,
    GOOGLE_PPC,
  );

  const { toggle: toggleFb } = useToggler(
    dispatch,
    FACEBOOK_MM,
  );

  const { toggle: toggleTrafficBlaster } = useToggler(
    dispatch,
    TRAFFIC_BLASTER,
  );

  const nextStep = useCallback((evt) => {
    if (evt) {
      evt.preventDefault();
    }

    setVisited(location);
    setLocation(nextLocation);
  }, [dispatch, nextLocation, location]);

  const previousStep = useCallback((evt) => {
    if (evt) {
      evt.preventDefault();
    }

    setLocation(previousLocation);
  }, [dispatch, previousLocation, location]);

  const setDetails = useCallback((details) => {
    dispatch({
      type: 'set-details',
      payload: details,
    });
  }, [dispatch]);

  const setSiteInfo = useCallback((details) => {
    dispatch({
      type: 'set-site-info',
      payload: details,
    });
  }, [dispatch]);

  const setBoard = useCallback((boardOptions) => {
    dispatch({
      type: 'set-board',
      payload: boardOptions,
    });
  }, [dispatch]);

  const setBoardCountry = useCallback((boardOptions) => {
    dispatch({
      type: 'set-board-country',
      payload: boardOptions,
    });
  }, [dispatch]);

  const setBoardState = useCallback((boardOptions) => {
    dispatch({
      type: 'set-board-state',
      payload: boardOptions,
    });
  }, [dispatch]);

  const setMlsId = useCallback((mlsId) => {
    dispatch({
      type: 'set-mls-id',
      payload: mlsId,
    });
  }, [dispatch]);

  const setBrokerInfoRequired = useCallback((required) => {
    dispatch({
      type: 'set-mls-broker-info-required',
      payload: required,
    });
  }, [dispatch]);

  const setBrokerInfo = useCallback((info) => {
    dispatch({
      type: 'set-mls-broker-info',
      payload: info,
    });
  }, [dispatch]);

  const setMarket = useCallback((marketOptions) => {
    dispatch({
      type: 'set-market',
      payload: marketOptions,
    });
  }, [dispatch]);

  const setVisited = useCallback((visited) => {
    dispatch({
      type: 'set-visited',
      payload: visited,
    });
  }, [dispatch]);

  const setPromoCode = useCallback((promoCode) => {
    dispatch({
      type: 'set-promo-code',
      payload: promoCode,
    });
  }, [dispatch]);

  const setBilling = useCallback((plan, packages) => {
    dispatch({
      type: 'set-billing-plan',
      payload: {
        plan,
        packages
      }
    });
  }, [dispatch]);

  const setCommitment = useCallback((commitment) => {
    dispatch({
      type: 'set-commitment',
      payload: commitment,
    });
  }, [dispatch]);

  const setVisitedHere = useCallback(() => {
    setVisited(location);
  }, [setVisited, location]);

  const setCurrentLocation = useCallback((curr) => {
    dispatch({
      type: 'set-current-location',
      payload: curr,
    });
  }, [dispatch]);

  const invalidateVisitedBeforeTOS = useCallback(() => {
    dispatch({
      type: 'invalidate-visited-before-tos',
    });
  }, [location]);

  const setDomainPurchase = useCallback((should) => {
    dispatch({
      type: 'set-domain-purchase',
      payload: should,
    });
  }, [dispatch]);

  const setGeekAi = useCallback((enabled, packages) => {
    dispatch({
      type: 'set-geek-ai',
      payload: {enabled, packages}
    });
  }, [dispatch]);

  const setSystemSetup = useCallback((enabled, packages) => {
    dispatch({
      type: 'set-system-setup',
      payload: {enabled, packages}
    });
  }, [dispatch]);

  const setLiveTraining = useCallback((enabled, packages) => {
    dispatch({
      type: 'set-live-training',
      payload: {enabled, packages},
    });
  }, [dispatch]);

  const setSoloTrainingSessions = useCallback((enabled, packages) => {
    dispatch({
      type: 'set-solo-training-sessions',
      payload: {enabled, packages}
    });
  }, [dispatch]);

  return {
    dispatch,
    nextStep,
    previousStep,
    toggleGoogle,
    toggleFb,
    toggleTrafficBlaster,
    setDetails,
    setBoard,
    setBoardCountry,
    setBoardState,
    setMlsId,
    setMarket,
    setSiteInfo,
    setVisited,
    setCurrentLocation,
    setVisitedHere,
    setBrokerInfo,
    setBilling,
    setCommitment,
    setPromoCode,
    setBrokerInfoRequired,
    invalidateVisitedBeforeTOS,
    setDomainPurchase,
    setGeekAi,
    setSystemSetup,
    setLiveTraining,
    setSoloTrainingSessions,
  };
};

export const useWizardProps = () => {
  const props = useContext(WizardPropsContext);

  return props;
};


export const useWizardState = () => {
  const state = useContext(WizardContext);

  const selectedAddons = useMemo(() => ({
    google: state.addons[GOOGLE_PPC],
    fb: state.addons[FACEBOOK_MM],
    traffic_blaster: state.addons[TRAFFIC_BLASTER],
    geek_ai: state.addons.geek_ai,
    system_setup: state.addons.system_setup,
    live_training: state.addons.live_training,
    solo_training: state.addons.solo_training,
  }), [state.addons]);

  const returnState = useMemo(() => ({
    ...state,
    addons: selectedAddons,
  }));

  return returnState;
};


export const useWizardStep = (STEPS) => {
  const state = useWizardState();

  const CurrStep = useMemo(
    () => {
      if (state.currentStep >= STEPS.length) {
        return STEPS[STEPS.length - 1];
      }

      return STEPS[state.currentStep];
    },
    [state.currentStep]
  );

  return CurrStep;
};



// Re exporting from board state
export {
  useBoardOptionState,
  useBoardOptionPreloader,
  useBoardOptions,
  useBoardById,
  useMarketOptionState,
  useMarketOptionsLoader,
  useMarketOptions,
};
