import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useLocation } from 'wouter';

import { mq } from '../styles/breakpoints';
import routes from '../steps/routes';
import { useWizardState } from '../state';


const circleColorStyles = ({ completed = false }) => {
  if (!completed) {
    return css`
      background-color: #114572;
    `;
  }

  else {
    return css`
      background-color: #4592ff;
    `;
  }
};

const StepCircle = styled.div`
  border-radius: 100%;
  width: 12px;
  height: 12px;

  ${circleColorStyles}
`;


const StepIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 1em;

  div {
    margin: 0.5em;
  }
`;


const useCurrentIndex = (firstStep) => {
  const [location, setLocation] = useLocation();

  const currentIndex = useMemo(
    () => routes.indexOf(location, firstStep),
    [location],
  );

  return currentIndex;
};

const StepIndicators = ({ firstStep = 0 }) => {
  const currentStep = useCurrentIndex(firstStep);

  const indicators = useMemo(
    () => routes.slice(firstStep).map(
      (s, i) => <StepCircle completed={i <= (currentStep - firstStep)} key={i} />
    ),
    [currentStep]);

  return (
    <StepIndicatorContainer>
      { indicators }
    </StepIndicatorContainer>
  );
};


export default StepIndicators;
