import React from 'react';
import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';

const displayStyles = ({ flex = false, inline = false, block = true }) => {
  if (!flex || block) {
    if (inline && block) {
      return 'display: inline-block;';
    }

    if (inline) {
      return 'display: inline;';
    }

    return 'display: block;';
  }

  if (inline && flex) {
    return `
      display: inline-flex;
    `;
  }

  if (flex) {
    return `
      display: flex;
    `;
  }
};

const labelStyles = ({ styleLabel = true, bg = null }) => {
  if (styleLabel) {
    return `
      label {
        position: absolute;
        background-color: ${bg ? bg : 'white'};
        top: -20%;
        left: 3%;
        padding: 0em 0.5em;
      }
    `;
  }

  return '';
};

const inputStyles = ({ styleInput = true, hasError = false, bg = null, inputPadding = null }) => {
  if (styleInput) {
    let borderColor = '#D7D7D7';

    if (hasError) {
      borderColor = '#F86621';
    }

    return `
      input, textarea, .Stripe-Element {
        box-sizing: border-box;
        padding: ${inputPadding ? inputPadding : '1.3em'};
        font-size: 1em;
        display: inline-block;
        width: 100%;
        height: 100%;
        border: 1px solid ${borderColor};
        border-radius: 5px;
        ${bg ? `background-color: ${bg};` : ''}

        &:focus {
          border: 1px solid #327FEC;
        }
      }
    `;
  }

  return '';
};

const sizingStyles = ({ half = false }) => {
  if (half) {
    return `
      max-width: 45%;
      width: 45%;
    `;
  }

  return '';
};

const marginStyles = ({ noMargin = false, m = null, mDir = null }) => {
  if (noMargin) {
    return '';
  }

  if (m) {
    let suffix = '';

    if (mDir) {
      suffix = `-${mDir}`;
    }

    return `margin${suffix}: ${m}em;`;
  };

  return 'margin: 1em;';
};

const ErrorTriangle = styled.img`
  position: absolute;
  right: ${({ right }) => right || 5}%;
  top: 37%;

  ${mq.sm} {
    right: 5%;
  }
`;



const bgStyles = ({ bg = null }) => {
  if (bg) {
    return `background-color: ${bg};`;
  }

  else {
    return 'background-color: white;';
  }
};

const InputGroup = styled.div`
  ${bgStyles}
  position: relative;
  ${marginStyles}
  color: #787878;
  ${displayStyles}

  ${labelStyles}
  ${inputStyles}
  ${sizingStyles}
`;

export default ({ hasError, children, errorTriangleRight, ...props }) => {
  return (
    <>
      <InputGroup hasError={hasError} {...props}>
        {children}
        { hasError && <ErrorTriangle right={errorTriangleRight} src="/static/images/exclamation-triangle.svg"/> }
      </InputGroup>
    </>
  );
};
