import styled from '@emotion/styled';
import { mq } from '../../styles/breakpoints';

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ row = true }) => row ? 'row' : 'column'};
  justify-content: space-between;

  ${({ margin = null }) => margin ? `margin: ${margin};` : ''}

  ${mq.sm} {
    flex-direction: ${({ directionSm = null }) => directionSm ? directionSm : 'column'} !important;
    justify-content: ${({ justifySm = null }) => justifySm ? justifySm : 'flex-start'};
    align-items: ${({ alignItems = null }) => alignItems ? alignItems : 'center'};
    align-content: flex-start;
  }
`;

export default Flex;
