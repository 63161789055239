import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';


const ContinueButton = styled.button`
  margin: 0em ${({ marginY = '2em' }) => marginY};
  background: #4592ff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 1em 3.5em 1em 3.5em;
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 3em;
  border: none;
  cursor: pointer;

  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ disabled }) => disabled && 'background-color: silver;'}

  ${mq.sm} {
    margin-bottom: 0px;
  }
`;


export default ContinueButton;
