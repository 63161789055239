//
// for use with the payment form component
// ideally the form should keep track of it's state
// using react context, but the current implementation
// makes that... hard
//
export const ZIP_PATTERNS = {
  US: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  MX: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  CA: /(?=[^dfioqu]+$)(?![wx])[a-z]\d[a-z]\s?\d[a-z]\d/i
}

export const COUNTRY_OPTIONS = [
  {
    label: 'US',
    value: 'US',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
]

export const TERRITORY_OPTIONS = {
  US: [
    {label: "Alaska", value: "AK"},
    {label: "Alabama", value: "AL"},
    {label: "Arizona", value: "AZ"},
    {label: "Arkansas", value: "AR"},
    {label: "California", value: "CA"},
    {label: "Colorado", value: "CO"},
    {label: "Connecticut", value: "CT"},
    {label: "Delaware", value: "DE"},
    {label: "Florida", value: "FL"},
    {label: "Georgia", value: "GA"},
    {label: "Guam", value: "GU"},
    {label: "Hawaii", value: "HI"},
    {label: "Idaho", value: "ID"},
    {label: "Illinois", value: "IL"},
    {label: "Indiana", value: "IN"},
    {label: "Iowa", value: "IA"},
    {label: "Kansas", value: "KS"},
    {label: "Kentucky", value: "KY"},
    {label: "Louisiana", value: "LA"},
    {label: "Maine", value: "ME"},
    {label: "Maryland", value: "MD"},
    {label: "Massachusetts", value: "MA"},
    {label: "Michigan", value: "MI"},
    {label: "Minnesota", value: "MN"},
    {label: "Mississippi", value: "MS"},
    {label: "Missouri", value: "MO"},
    {label: "Montana", value: "MT"},
    {label: "Nebraska", value: "NE"},
    {label: "Nevada", value: "NV"},
    {label: "New Hampshire", value: "NH"},
    {label: "New Jersey", value: "NJ"},
    {label: "New Mexico", value: "NM"},
    {label: "New York", value: "NY"},
    {label: "North Carolina", value: "NC"},
    {label: "North Dakota", value: "ND"},
    {label: "Ohio", value: "OH"},
    {label: "Oklahoma", value: "OK"},
    {label: "Oregon", value: "OR"},
    {label: "Pennsylvania", value: "PA"},
    {label: "Rhode Island", value: "RI"},
    {label: "South Carolina", value: "SC"},
    {label: "South Dakota", value: "SD"},
    {label: "Tennessee", value: "TN"},
    {label: "Texas", value: "TX"},
    {label: "Utah", value: "UT"},
    {label: "Vermont", value: "VT"},
    {label: "Virginia", value: "VA"},
    {label: "Washington", value: "WA"},
    {label: "Washington D.C.", value: "DC"},
    {label: "West Virginia", value: "WV"},
    {label: "Wisconsin", value: "WI"},
    {label: "Wyoming", value: "WY"}
  ],
  MX: [
    {label: "Aguascalientes", value: "AG"},
    {label: "Baja California", value: "BC"},
    {label: "Baja California Sur", value: "BS"},
    {label: "Campeche", value: "CM"},
    {label: "Chiapas", value: "CS"},
    {label: "Chihuahua", value: "CH"},
    {label: "Coahuila", value: "CO"},
    {label: "Colima", value: "CL"},
    {label: "Durango", value: "DG"},
    {label: "Guanajuato", value: "GT"},
    {label: "Guerrero", value: "GR"},
    {label: "Hidalgo", value: "HA"},
    {label: "Jalisco", value: "JA"},
    {label: "México", value: "EM"},
    {label: "Mexico City", value: "DF"},
    {label: "Michoacán", value: "MI"},
    {label: "Morelos", value: "MO"},
    {label: "Nayarit", value: "NA"},
    {label: "Nuevo León", value: "NL"},
    {label: "Oaxaca", value: "OA"},
    {label: "Puebla", value: "PU"},
    {label: "Querétaro", value: "QT"},
    {label: "Quintana Roo", value: "QR"},
    {label: "San Luis Potosí", value: "SL"},
    {label: "Sinaloa", value: "SI"},
    {label: "Sonora", value: "SO"},
    {label: "Tabasco", value: "TB"},
    {label: "Tamaulipas", value: "TM"},
    {label: "Tlaxcala", value: "TL"},
    {label: "Veracruz", value: "VE"},
    {label: "Yucatán", value: "YU"},
    {label: "Zacatecas", value: "ZA"}
  ],
  CA: [
    {label: "Alberta", value: "AB"},
    {label: "British Columbia", value: "BC"},
    {label: "Manitoba", value: "MB"},
    {label: "New Brunswick", value: "NB"},
    {label: "Newfoundland and Labrador", value: "NL"},
    {label: "Northwest Territories", value: "NT"},
    {label: "Nova Scotia", value: "NS"},
    {label: "Nunavut", value: "NU"},
    {label: "Ontario", value: "ON"},
    {label: "Prince Edward Island", value: "PE"},
    {label: "Quebec", value: "QC"},
    {label: "Saskatchewan", value: "SK"},
    {label: "Yukon", value: "YT"}
  ]
}
