import React from 'react';
import styled from '@emotion/styled';

import { mq } from '../styles/breakpoints';

const ClearButton = styled.div`
  display: inline-flex;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 4%;
  left: 4%;
  color: #787878;
  cursor: pointer;

  ${mq.sm} {
    top: 2%;
  }

  span {
    margin-left: 0.5em;
  }
`;


export default ClearButton;
