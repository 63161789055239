/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, {
  useEffect,
  useReducer,
} from 'react';
import { animateScroll as scroll } from 'react-scroll';

import Flex from './Flex';
import OrderSummary from './OrderSummary';
import PaymentForm from './PaymentForm';
import Card from '../../components/Card';
import ContinueButton from '../../components/ContinueButton';


const MOBILE_CART_DEFAUT_STATE = {
  currentStep: 0,
};


const mobileCartReducer = (state, action) => {
  switch (action.type) {
    case 'next-step': {
      if (state.currentStep === 1) {
        return state;
      }

      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }

    case 'previous-step': {
      if (state.currentStep === 0) {
        return state;
      }

      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    }

    default: {
      return state;
    }
  }
};

export default function MobileShoppingCart({
  promoCode,
  onValidPromo,
  validatePromo,
  setValidatePromo,
  mustApplyPromoError,
  setMustApplyPromoError,
}) {
  const [state, dispatch] = useReducer(
    mobileCartReducer,
    MOBILE_CART_DEFAUT_STATE,
  );

  const backClick = (evt) => {
    evt.preventDefault();

    dispatch({ type: 'previous-step' });
  };

  const continueClick = (evt) => {
    evt.preventDefault();    
    dispatch({ type: 'next-step' });
  };

  useEffect(() => {
    scroll.scrollTo(100);
  }, [state.currentStep]);

  useEffect(() => {
    if (mustApplyPromoError) {
      scroll.scrollTo(400);
    }
  }, [mustApplyPromoError]);

  // render one at a time
  return (
    <React.Fragment>
      <Flex>
        { state.currentStep === 0 && (
          <OrderSummary
            onValidPromo={onValidPromo}
            promoCode={promoCode}
            validatePromo={validatePromo}
            setValidatePromo={setValidatePromo}
            mustApplyPromoError={mustApplyPromoError}
            setMustApplyPromoError={setMustApplyPromoError}
          />
        ) }

        { state.currentStep === 1 && (
          <Card padding="0em" css={css`max-width: unset !important;`}>
            <PaymentForm
              promoCode={promoCode}
              mobile
              onBackClick={backClick}
              setValidatePromo={setValidatePromo}
              mustApplyPromoError={mustApplyPromoError}
              setMustApplyPromoError={setMustApplyPromoError}
            />
          </Card>
        )
        }
      </Flex>

    {
      state.currentStep === 0 && (
        <ContinueButton
          onClick={continueClick}
          css={css`position: fixed; bottom: 1em; z-index: 101; margin: 0;`}>
          CONTINUE
        </ContinueButton>
      )
    }
    </React.Fragment>
  );
};
