import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './wizard/';


Sentry.init({
  dsn: "https://e92ccf1ea3c145718b7e243fb8625746@o451.ingest.sentry.io/5584195",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 0.8,
});


import { mount, getData, getCsrfToken } from './util';

Modal.setAppElement('#mount');

const boardOptionsUrl = getData('board-options-url');
const marketOptionsUrl = getData('market-options-url');
const tosUrl = getData('tos-url');
const mlsPaperworkUrl = JSON.parse(getData('mls-paperwork-url'));
const siteCreationStatusUrl = JSON.parse(getData('site-creation-status-url'));
const mlsBrokerInfoRequiredUrl = getData('mls-broker-required-url');
const orderUrl = getData('order-url');
const prepareOrderUrl = getData('prepare-order-url');
const checkPromoUrl = getData('check-promo-url');
const loggedIn = JSON.parse(getData('logged-in')) === 'True';
const signUpFee = getData('signup-fee');
const conversionCodes = getData('ccodes');
const opportunityID = JSON.parse(getData('opp-id'));
const validSiteUrl = getData('valid-site-url');
const usedEmailCheckUrl = getData('used-email-check-url');
const ownDomainCheckUrl = getData('own-domain-check-url');
const domainAvailableUrl = getData('domain-available-url');
const domainPurchaseUrl = getData('domain-purchase-url');
const shortcode = getData('shortcode');
const packages = JSON.parse(getData('packages'));
const initialPackage = getData('package');
const initialCommitment = getData('commitment');

let userDetails = getData('user-details');

if (userDetails) {
  userDetails = JSON.parse(userDetails);
}

ReactDOM.render(
  <App
    boardOptionsUrl={boardOptionsUrl}
    marketOptionsUrl={marketOptionsUrl}
    tosUrl={tosUrl}
    mlsPaperworkUrl={mlsPaperworkUrl}
    siteCreationStatusUrl={siteCreationStatusUrl}
    mlsBrokerInfoRequiredUrl={mlsBrokerInfoRequiredUrl}
    orderUrl={orderUrl}
    prepareOrderUrl={prepareOrderUrl}
    loggedIn={loggedIn}
    signUpFee={signUpFee}
    conversionCodes={conversionCodes}
    userDetails={userDetails}
    opportunityID={opportunityID}
    validSiteUrl={validSiteUrl}
    checkPromoUrl={checkPromoUrl}
    usedEmailCheckUrl={usedEmailCheckUrl}
    ownDomainCheckUrl={ownDomainCheckUrl}
    domainAvailableUrl={domainAvailableUrl}
    domainPurchaseUrl={domainPurchaseUrl}
    shortcode={shortcode}
    csrfToken={getCsrfToken()}
    packages={packages}
    initialPackage={initialPackage}
    initialCommitment={initialCommitment}
  />,
  mount[0],
);
