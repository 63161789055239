import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';
import { ErrorMessage } from 'react-hook-form';


const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 50px #00000029;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 3em 4em 4em 4em;
  z-index: 1;
  width: 30vw;
  max-width: 40vw;

  h2 {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  [contenteditable="true"]:focus {
      outline: none;
  }

  ${mq.sm} {
    width: 80vw;
    max-width: 80vw;
    padding: 2.5em 1em 1em 1em;
    margin: 2em 1em 1em 1em;

    h2 {
      margin: 1em;
    }
  }
`;


export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #F86621;
  margin: 1em 0em;

  p {
    text-align: center;
    /* margin: 0em 0em 0.5em 0em; */
    line-height: 24px;
    max-width: 90%;
  }
`;


export const useErrorMessages = ({ errors, field = null, requiredErrorMessage='All fields are required' }) => {
  if (field) {
    return (
      <ErrorMessageContainer key={`${field}-err-container`}>
        <ErrorMessage as="p" key={`${field}-lvl-err`} name={field} errors={errors} />
      </ErrorMessageContainer>
    );
  }

  const fieldNames = Object.keys(errors);

  let messages = [];
  let atLeastOneEmptyValue = false;

  for (const field of fieldNames) {
    const hasOneRequiredType = errors[field].type === 'required';
    const hasManyTypes = errors[field].types && Object.keys(errors[field].types).length > 0;

    if (hasOneRequiredType || (hasManyTypes && errors[field].types.required)) {
      atLeastOneEmptyValue = true;
    }

    else {
      messages.push(
        <ErrorMessage as="p" key={`${field}-err`} name={field} errors={errors} />
      );
    }
  }

  if (atLeastOneEmptyValue) {
    messages = [
      <p key="required-err">{ requiredErrorMessage }</p>,
      ...messages,
    ];
  }


  if (messages.length > 0) {
    return (
      <ErrorMessageContainer>
        { messages }
      </ErrorMessageContainer>
    );
  }

  else {
    return null;
  }
};


export default Form;
