import { css } from '@emotion/core';
import { mq } from './breakpoints';

export const hideOnMobile = css`
  ${mq.xs} {
    display: none;
  }

  ${mq.sm} {
    display: none;
  }
`;

export const hideOnDesktop = css`
  ${mq.lg} {
    display: none;
  }

  ${mq.xl} {
    display: none;
  }

  ${mq.md} {
    display: none;
  }
`;
