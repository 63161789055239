import styled from '@emotion/styled';
import { mq } from '../../styles/breakpoints';

export const Loader = styled.div`
  text-align: center;
  margin-bottom: 5em;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 3em 4em 4em 4em;
  width: 30vw;
  max-width: 40vw;

  img {
    width: 100%;
  }

  h4 {
    font-weight: normal;
  }

  ${mq.sm} {
    width: 60vw;
    max-width: 60vw;
    padding: 2.5em 1em 1em 1em;
    margin: 2em 1em 1em 1em;

    h4 {
      margin: 1em;
    }
  }

  ${mq.xs} {
    width: 80vw;
    max-width: 80vw;
    padding: 2.5em 1em 1em 1em;
    margin: 2em 1em 1em 1em;

    h4 {
      margin: 1em;
    }
  }
`;

export const StateLabel = styled.label`
  z-index: 1;
`;

export const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #787878;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block;
  appearance: none;
  margin-top: 4px;
  margin-right: 1em;
  min-width: 16px;

  &:active, &:checked:active {
    background-color: #15C639;
    border-color: #15C639;
  }

  &:checked {
    background-color: #15C639;
    border-color: #15C639;
  }

  &:checked:after {
    content: "\\2713";
    font-weight: 900;
    font-size: 0.9em;
    color: white;
    position: relative;
    left: 15%;
    background-color: #15C639;
    border-color: #15C639;
  }
`;

export function selectStyles(hasError) {
  return ({
    input: styles => ({
      ...styles,
      padding: '1em',
    }),
    placeholder: styles => ({
      ...styles,
      paddingLeft: '1em',
    }),
    option: styles => ({
      ...styles,
      paddingLeft: '1.5em',
    }),
    singleValue: styles => ({
      ...styles,
      paddingLeft: '1em',
    }),
    menu: styles => ({
      ...styles,
      zIndex: '2',
    }),
    valueContainer: styles => ({
      ...styles,
      marginRight: '24px',
    }),
    container: styles => ({
      ...styles,
      border: hasError ? '1px solid #F86621' : undefined,
      borderRadius: '5px',
    }),
  });
}
