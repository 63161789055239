/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useLayoutEffect,
  useState,
} from 'react';
import { useRoute } from 'wouter';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Flex from './Flex';
import MobileShoppingCart from './MobileShoppingCart';
import OrderSummary from './OrderSummary';
import PaymentForm from './PaymentForm';
import ObservedVisitRoute from '../../components/ObservedVisitRoute';
import OuterWizardContainer from '../../components/OuterWizardContainer';
import Wizard from '../../components/Wizard';
import DetailsContainer from '../../components/DetailsContainer';
import useBreakpointDetector from '../../util/use-breakpoint-detector';
import { getData } from '../../../util';
import {
  useWizardActions,
  useWizardState,
  useWizardProps,
} from '../../state';

const STRIPE_KEY = JSON.parse(getData('stripe-key'));
const stripePromise = loadStripe(STRIPE_KEY);

const withStripe = (Component) => ({ ...props }) => (
  <Elements stripe={stripePromise}>
    <Component {...props} />
  </Elements>
);

const Cart = withStripe(function PaymentScreen() {
  const {
    prepareOrderUrl,
  } = useWizardProps();
  const {
    billingPlan,
    promoCode,
    siteInfo: { site_domain },
  } = useWizardState();
  const [prepared, setPrepared] = useState(false);

  const { setPromoCode } = useWizardActions();

  const isMobileScreen = useBreakpointDetector('md');

  const onPrepare = useCallback(() => setPrepared(true), [setPrepared]);

  const [validatePromo, setValidatePromo] = useState(false);
  const [mustApplyPromoError, setMustApplyPromoError] = useState(false);

  useEffect(() => {
    if (!prepared) {
      $.post(JSON.parse(prepareOrderUrl), JSON.stringify({
        SITE_DOMAIN: site_domain,
      })).done(onPrepare);
    }
  }, [prepareOrderUrl, prepared, site_domain, onPrepare]);

  useLayoutEffect(() => {
    // NOTE: i hate myself for this :(
    $('.applicationClass').css('background-size', '100vw 30%');

    return () => $('.applicationClass').css('background-size', '100vw 50%');
  }, []);

  if (isMobileScreen) {
    return (
      <MobileShoppingCart
        onValidPromo={setPromoCode}
        promoCode={promoCode}
        validatePromo={validatePromo}
        setValidatePromo={setValidatePromo}
        mustApplyPromoError={mustApplyPromoError}
        setMustApplyPromoError={setMustApplyPromoError}
      />
    );
  }

  return (
    <Flex css={css`justify-content: space-around; width: 80vw;`}>
      <OrderSummary
        onValidPromo={setPromoCode}
        promoCode={promoCode}
        validatePromo={validatePromo}
        setValidatePromo={setValidatePromo}
        mustApplyPromoError={mustApplyPromoError}
        setMustApplyPromoError={setMustApplyPromoError}
      />
      <PaymentForm
        promoCode={promoCode}
        setValidatePromo={setValidatePromo}
        mustApplyPromoError={mustApplyPromoError}
        setMustApplyPromoError={setMustApplyPromoError}
      />
    </Flex>
  );
});

export default function ShoppingCart() {
  return (
    <ObservedVisitRoute>
      <OuterWizardContainer>
        <Wizard column>
          <Cart />
        </Wizard>
      </OuterWizardContainer>
    </ObservedVisitRoute>
  );
}
