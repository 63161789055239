export const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
};


export const mq = Object.keys(breakpoints).reduce(
  (acc, bp) => ({
    ...acc,
    [bp]: `@media screen and (max-width: ${breakpoints[bp]}px)`,
  }),
  {},
);
