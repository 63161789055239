import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';


const Wizard = styled.div`
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5em;
  max-width: 85vw;
  gap: 2em;

  flex-direction: ${({ column = false }) => column ? 'column' : 'row'};

  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}

  ${mq.sm} {
    flex-direction: column !important;
    margin-top: 2em;
    align-items: center;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column !important;
    margin-top: 2em;
  }
`;


export default Wizard;
