import React, {
  createContext,
  useContext,
  useState,
} from 'react';

const MarketOptionContext = createContext();

export default function MarketOptionProvider({ children, marketOptionsUrl }) {
  const [marketOptions, setMarketOptions] = useState([]);

  const state = {
    marketOptions,
    setMarketOptions,
    marketOptionsUrl
  };

  return (
    <MarketOptionContext.Provider value={state}>
      { children }
    </MarketOptionContext.Provider>
  );
}

/**
 * Returns the stored market options as kept within an internal reducer.
 */
export const useMarketOptionState = () => {
  return useContext(MarketOptionContext);
};

/**
 * Fetches the market options data from the backend and stores it in the internal reducer.
 */
export const useMarketOptionsLoader = (state) => {
  const {
    setMarketOptions,
    marketOptionsUrl,
  } = useMarketOptionState();

    if (marketOptionsUrl) {
      $.get(JSON.parse(marketOptionsUrl), {state: state}).done(setMarketOptions);}
};

/**
 * Returns the stored market options
 */
export const useMarketOptions = () => {
  const { marketOptions } = useMarketOptionState();
  return marketOptions;
};
