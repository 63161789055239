import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';


const DetailsContainer = styled.div`
  background: #114572 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 60px #00000029;
  border-radius: 6px;
  color: white;
  padding: 3em 5em 2em 5em;
  margin-left: -1em;
  height: 20em;
  width: 24em;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 500;
  }

  ${mq.sm} {
    padding: 2em;
    margin: 2em;
    margin-top: -0.5em;
    padding-top: 3em;
    width: 70vw;
    max-width: 70vw;
    height: 16em;
  }

  .switch-container {
    display: flex;
    margin-top: 1em;
    margin-left: -1.5em;
    justify-content: space-between;
    flex-grow: 1;

    ${mq.md} {
      flex-direction: column;
    }

    ul {
      padding-left: 1.6em;
      padding-right: 2em;

      &:last-child {
        padding-right: 0px;
      }
    }

    li {
      padding-bottom: 1em;

      &:last-child {
        padding-bottom: 0px;
      }
    }
  }

  .list-container {
    display: flex;
    margin-top: 1em;
    margin-left: -1.5em;
    justify-content: space-between;

    ${mq.md} {
      flex-direction: column;
    }

    ul {
      padding-right: 2em;
      padding-left: 2.6em;

      &:last-child {
        padding-right: 0px;
      }
    }

    li {
      padding-bottom: 1em;
      list-style-image: url('/static/images/List_Dash_updated-12.svg');
      padding-left: 0.5em;

      &:last-child {
        padding-bottom: 0px;
      }
    }
  }
`;


export default DetailsContainer;
