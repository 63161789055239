/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import {
  useBoardOptionPreloader,
  useWizardActions,
  useWizardProps,
  useWizardState,
} from '../state';

import ClearButton from '../components/ClearButton';
import ContinueButton from '../components/ContinueButton';
import Form, { useErrorMessages, ErrorMessageContainer } from '../components/Form';
import InputGroup from '../components/InputGroup';
import ObservedVisitRoute from '../components/ObservedVisitRoute';
import OuterWizardContainer from '../components/OuterWizardContainer';
import StepIndicators from '../components/StepIndicators';
import Wizard from '../components/Wizard';

import EMAIL_REGEX from '../util/email-regex';
import useBreakpointDetector from '../util/use-breakpoint-detector';
import { SignUpHeader } from './headers';

const Legalese = styled.p`
  margin: 0em 1em 1em 1em;
  color: #787878;
  line-height: 24px;

  a {
    color: #4592ff;
  }
`;

const getCurrentPathSegments = () => {
  const host = window.location.host;
  const href = window.location.href;

  const lastIndexOfHost = href.indexOf(host) + host.length;
  const indexOfHash = href.indexOf('#');

  return href.substring(lastIndexOfHost, indexOfHash);
};

function AccountDetailsForm() {
  const {
    nextStep,
    previousStep,
    setDetails,
    setVisitedHere,
  } = useWizardActions();

  const {
    userDetails,
  } = useWizardState();

  const {
    userDetails: userDetailProps,
    usedEmailCheckUrl,
    shortcode,
    loggedIn,
  } = useWizardProps();

  let defaultFormValues = userDetails === null ? {} : userDetails;

  if (userDetailProps) {
    defaultFormValues = userDetailProps;
  }

  if (defaultFormValues.full_name) {
    const splitName = defaultFormValues.full_name.split(' ');
    if (splitName.length > 1) {
      defaultFormValues.firstName = splitName[0];
      defaultFormValues.lastName = splitName[1];
    }
  }

  const { handleSubmit, register, errors, watch, setError, clearError } = useForm({
    validateCriteriaMode: 'all',
    nativeValidation: true,
    defaultValues: defaultFormValues,
    mode: 'onBlur',
  });

  const isMobileScreen = useBreakpointDetector('sm');

  const [submitted, setSubmitted] = useState(null);
  const [emailInUse, setEmailInUse] = useState(false);

  const formSubmit = (d, evt) => {
    const formData = {
      firstName: d.firstName,
      lastName: d.lastName,
      email: d.email,
      phone_number: d.phone_number,
    };

    if (!loggedIn) {
      formData.password = d.password;
    }

    if (emailInUse && !loggedIn) {
      return;
    }

    setSubmitted(formData);
  };

  useBoardOptionPreloader();

  useEffect(() => {
    if (submitted) {
      setVisitedHere();
      setDetails(submitted);
      nextStep();
    }
  }, [submitted]);

  const pw = watch('password');

  const validateConfirmPassword = useCallback(
    (value) => value === pw || 'Your passwords must both match.',
    [pw],
  );

  const email = watch('email');

  useEffect(() => {
    if (!userDetailProps || shortcode) {
      if (!email) {
        return () => {};
      }

      const timeoutHandler = setTimeout(() => {
        $.get(
          JSON.parse(usedEmailCheckUrl),
          { email },
        ).done((res) => {
          setEmailInUse(false);
          if (clearError) {
            clearError('email', { exact: false });
          }
        }).fail((res) => {
          if (res.status === 400) {
            setEmailInUse(true);
            setError('email', {
              type: 'manual',
              message: 'This email is already in use',
            });
          }
        });
      }, 700);

      return () => {
        if (clearError) {
          clearError('email', { exact: false });
        }

        setEmailInUse(false);
        clearTimeout(timeoutHandler);
      };
    }

    return () => {};
  }, [userDetailProps, email, setError, clearError]);

  const errorMessages = useErrorMessages({ errors });

  const handleUserKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(formSubmit)();
    }
  };

  const onBackClick = (e) => {
    e.preventDefault();
    previousStep();
  };

  return (
    <Form onSubmit={handleSubmit(formSubmit)}>
      <ClearButton onClick={onBackClick}>
        <img src="/static/images/BackArrowGrey.svg" />
        <span>Back</span>
      </ClearButton>

      {
        loggedIn ? (
          <h2>Please verify your account information</h2>
        ) : (
          <h2>Account Information</h2>
        )
      }

      <InputGroup hasError={errors.firstName}>
        <label>First Name</label>
        <input name="firstName" onKeyPress={handleUserKeyPress} type="text" ref={
          register({
            required: true,
            validate: value => !/(,|\<|\>)/g.test(value) || 'First name name cannot include commas or angle brackets (e.g. \',\' \'<\' \'>\')'
          })
        }></input>
      </InputGroup>

      <InputGroup hasError={errors.lastName}>
        <label>Last Name</label>
        <input name="lastName" onKeyPress={handleUserKeyPress} type="text" ref={
          register({
            required: true,
            validate: value => !/(,|\<|\>)/g.test(value) || 'Last name name cannot include commas or angle brackets (e.g. \',\' \'<\' \'>\')'
          })
        }></input>
      </InputGroup>

      <InputGroup hasError={!!errors.email && !loggedIn}>
        <label>Email</label>
        <input disabled={loggedIn} onKeyPress={handleUserKeyPress} name="email" type="email" ref={
          register({
            required: true,
            pattern: {
              value: EMAIL_REGEX,
              message: 'Please enter a valid email address.',
            },
          })
        }></input>
      </InputGroup>

      <InputGroup hasError={errors.phone_number}>
        <label htmlFor="phone_number">Phone Number</label>
        <input autoComplete="tel" disabled={loggedIn} onKeyPress={handleUserKeyPress} name="phone_number" pattern="^[2-9][0-9]{9}" type="tel" ref={
          register({
            required: !loggedIn,
            pattern: /^[2-9][0-9]{9}/,
            validate: value => loggedIn ? true : ((value && value !== '' && value.match(/^\d+$/) && value.replace(/^\D+/g, '').length >= 10) || 'Please enter a valid phone number. It must be 10 digits long with no special characters.')
          })
        }></input>
      </InputGroup>

      <label style={{ padding: "0 1em 1em" }}>
        <input type="checkbox" name="texting_opt_in" ref={register({
          required: false,
        })} />
        <span style={{ color: "rgb(120, 120, 120)" }}> I agree to receive text messages*</span>
      </label>

      {
        loggedIn ?
          (
            null
          ) : (
            <React.Fragment>
              <InputGroup hasError={errors.confirm_password}>
                <label>Password</label>
                <input name="password" type="password" onKeyPress={handleUserKeyPress} ref={
                  register({
                    required: true,
                    minLength: {
                      value: 8,
                      message: 'Your password must contain at least 8 characters.',
                    },
                    maxLength: {
                      value: 32,
                      message: 'Your password must contain less than 32 characters.',
                    },
                  })
                }></input>
              </InputGroup>

              <InputGroup hasError={errors.confirm_password}>
                <label>Confirm Password</label>
                <input name="confirm_password" type="password" onKeyPress={handleUserKeyPress} ref={
                  register({
                    required: true,
                    minLength: {
                      value: 8,
                      message: 'Your password must contain at least 8 characters.',
                    },
                    maxLength: {
                      value: 32,
                      message: 'Your password must contain less than 32 characters.',
                    },
                    validate: {
                      matchesPassword: validateConfirmPassword,
                    },
                  })
                }></input>
              </InputGroup>
            </React.Fragment>
          )
      }

      { errorMessages }

      { (emailInUse && !loggedIn) &&
        <ErrorMessageContainer>
          <p>It looks like you already have an account. Click <a css={css`color: #327FEC;`} href={`/oauth/login/?next=${getCurrentPathSegments()}#/account`}>here</a> to log in.</p>
        </ErrorMessageContainer>
      }

      <ContinueButton marginY="1em" marginTop="1em" marginBottom="1em" onClick={handleSubmit(formSubmit)}>
        CONTINUE
      </ContinueButton>

      <Legalese>
        By clicking CONTINUE, you agree to our <a target="_blank" href="https://www.realgeeks.com/terms-and-conditions">Terms & Conditions</a> and <a target="_blank" href="https://www.realgeeks.com/privacy-policy/">Privacy Policy</a>.
        <br />
        <br />
        * By providing your number, you are expressly consenting, in
        writing, to receive telemarketing and other messages, including artificial
        or prerecorded voices, via automated calls or texts from Real Geeks at the
        number you provided above, with activity notifications and account updates.
        This consent is not required to purchase any good or service. Message and
        data rates may apply, frequency varies. Text HELP for help or STOP to cancel.
      </Legalese>
    </Form>
  );
};


export default function AccountDetails() {
  return (
    <ObservedVisitRoute>
      <SignUpHeader />

      <OuterWizardContainer>
        <Wizard column>
          <AccountDetailsForm />

          <StepIndicators firstStep={2} />
        </Wizard>
      </OuterWizardContainer>
    </ObservedVisitRoute>
  );
};
