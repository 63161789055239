/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';

export const InitHeader = () => (
  <div>
    <h1>We keep our options simple!</h1>
    <h1>It makes things easier for everyone.</h1>
  </div>
);


export const SignUpHeader = () => (
  <div>
    <h1>Set up your Real Geeks solution</h1>
    <h1>in just a few simple steps</h1>
  </div>
);
