import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';

import { useLocation } from 'wouter';

import ObservedVisitRoute from '../components/ObservedVisitRoute';
import OuterWizardContainer from '../components/OuterWizardContainer';
import Wizard from '../components/Wizard';

import {
  useWizardActions,
  useWizardState,
  useWizardProps,
} from '../state';

import useScript from '../util/use-script';

const CALENDLY_WIDGET_SRC = 'https://assets.calendly.com/assets/external/widget.js';
const CALENDLY_KICKOFF_URL = 'https://calendly.com/real-geeks-onboarding/kick-off';

const useCalendly = () => {
  const [scriptLoaded, scriptError] = useScript(CALENDLY_WIDGET_SRC);
  const [didFinish, setDidFinish] = useState(false);
  const [pending, setPending] = useState(false);
  const { addons, userDetails, siteInfo, promoCode } = useWizardState();

  useEffect(
    () => {
      if (!pending && scriptLoaded && !scriptError && !didFinish) {
        window.addEventListener("message", function(event) {
          if (event.origin !== "https://calendly.com") {
            return;
          }
          if (event.data.event === 'calendly.event_scheduled') {
            setDidFinish(true);
          }
        }, false);

        setPending(true);
      }
    },
    [
      pending,
      didFinish,
      scriptLoaded,
      scriptError,
    ],
  );

  const widgetMountPoint = useMemo(() => {
    const { google, fb } = addons;

    let baseUrl = CALENDLY_KICKOFF_URL;

    const {
      firstName,
      lastName,
      email,
      phone_number: phone,
    } = userDetails;

    const {
      site_domain,
      additional_notes,
    } = siteInfo;

    const dataUrl = encodeURI(`${baseUrl}?name=${firstName} ${lastName}&email=${email}&a1=${site_domain}&a2=1${phone}`);

    return (
      <div
        className="calendly-inline-widget"
        data-url={dataUrl}
        style={{
          height: '80vh',
          width: '80vw',
        }}
      />
    );
  }, [addons.google, addons.fb, userDetails, promoCode]);

  return [scriptLoaded, widgetMountPoint, didFinish];
};


const useMlsPaperwork = (submitted) => {
  const [location, setLocation] = useLocation();
  const { mlsPaperworkUrl } = useWizardProps();
  const {
    userDetails: { firstName, lastName, email, password },
    mlsBoard,
    mlsBrokerInfo,
    siteInfo,
  } = useWizardState();
  const {
    setVisitedHere,
  } = useWizardActions();
  const [finalRedirectUrl, setFinalRedirectUrl] = useState(null);
  const [siteCreated, setSiteCreated] = useState(false);

  useEffect(() => {
    // NOTE: collect the docusign url when we first mount
    if (mlsPaperworkUrl) {
      const payload = {
        email,
        first_name: firstName,
        last_name: lastName,

        board_id: mlsBoard.value.board_id,
        board_name: mlsBoard.value.name,
        domain: siteInfo.site_domain,
      };

      if (password) {
        payload.password = password;
      }

      if (mlsBrokerInfo) {
        payload.broker_name = mlsBrokerInfo.full_name;
        payload.broker_email = mlsBrokerInfo.email;
      }

      $.post(mlsPaperworkUrl, JSON.stringify(payload)).done((res) => {
        const { paperwork } = res;
        setSiteCreated(true);
        setFinalRedirectUrl(paperwork);
      }).fail((res) => {

        if (res.status === 412 || res.status === '412') {
          setSiteCreated(false);
        }

        else {
          // NOTE: I think this is for a site with a board of other
          setSiteCreated(true);
        }
      });

    }
  }, [
    mlsPaperworkUrl,
    firstName,
    lastName,
    email,
    password,
    mlsBoard,
    siteInfo,
  ]);

  useEffect(() => {
    if (!submitted) {
      return;
    }

    setVisitedHere();
    if (!siteCreated) {
      setLocation('/creating');
    }

    // NOTE: only redirect once we have the submission and the docusign url fetched
    else if (finalRedirectUrl) {
      window.location.href = finalRedirectUrl;
    }

    else {
      // NOTE: we should only get here if a value of other broke the mls
      // paperwork stuff since we didn't get a 412 and it failed
      setLocation('/thanks?event=signing_complete');
    }
  }, [submitted, finalRedirectUrl, siteCreated]);
};


function Calendly() {
  const [scriptLoaded, mountPoint, finishedScheduling] = useCalendly();

  useMlsPaperwork(finishedScheduling);

  return (
    <React.Fragment>
      { mountPoint }
    </React.Fragment>
  );
};


export default function ScheduleCall() {
  return (
    <ObservedVisitRoute>
      <OuterWizardContainer>
        <Wizard column>
          <Calendly />
        </Wizard>
      </OuterWizardContainer>
    </ObservedVisitRoute>
  );
};
