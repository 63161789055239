import React from 'react';
import styled from '@emotion/styled';
import { mq } from '../../styles/breakpoints';
import { useWizardState, useWizardActions, useWizardProps } from '../../state';

const Subtitle = styled.p`
  font-size: 0.9em;
  line-height: 24px;
  margin-top: -2em;
  margin-left: 2.5em;
`;

// Shamelessly stolen from:
// https://codesandbox.io/s/sleepy-resonance-14u1s?file=/src/index.js:1787-3494
const Root = styled.div`
  margin: 5px;
  cursor: pointer;
  width: ${props => (props.size || 20)}px;
  height: ${props => (props.size || 20)}px;
  position: relative;
  label {
    margin-left: 2em;

    span {
      font-weight: 500;
      font-size: 1.5em;
      display: inline-block;
      min-width: 250px;

      ${mq.sm} {
        font-size: 1.3em;
      }
    }
  }
  &::before {
    content: "";
    border-radius: 100%;
    border: 1px solid
      ${props => (props.borderColor || "#DDD")};
    background: ${props =>
      props.backgroundColor || "#FAFAFA"};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const Fill = styled.div`
  background: ${props => (props.fillColor || "#A475E4")};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: "";
    opacity: 0;
    width: calc(20px - 4px);
    position: absolute;
    height: calc(20px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid
      ${props => (props.borderActive || "#A475E4")};
    border-radius: 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 0.7em);
      height: calc(100% - 0.7em);
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 0.3s ease;
      }
    }
  }
`;

const Radio = ({ labelText, onChange, name, value, checked }) => {
  const fill = checked ? '#15C639' : 'white';
  return (
    <Root backgroundColor={fill} borderColor={fill}>
      <label>
        <span>{ labelText }</span>

        <Input
          type="radio"
          onChange={onChange}
          name={name}
          value={value}
          checked={checked}
          aria-checked={checked}
        />

        <Fill fillColor="white" borderActive="#15C639" />
      </label>
    </Root>
  );
};

const PlanForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
`;

export default function Details() {
  const { billingPlan, addons } = useWizardState();
  const { packages } = useWizardProps();
  const { setBilling } = useWizardActions();

  const onClick = (e) => {
    setBilling(e.target.value, packages);
  };

  return (
    <React.Fragment>
      <h2>Choose your payment plan</h2>
      <PlanForm>
        <Radio labelText="Monthly ($299/mo)" name="plan-type" onChange={onClick} checked={billingPlan === 'monthly'} value="monthly" />
        <Radio labelText="Yearly ($3289/yr)" name="plan-type" onChange={onClick} checked={billingPlan === 'annual'} value="annual" />
        <Subtitle>By selecting yearly the $500 sign up fee will be waived and get one month free!</Subtitle>
      </PlanForm>
    </React.Fragment>
  );
};
