/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, {
  useEffect,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { useLocation } from 'wouter';

import {
  useWizardActions,
  useWizardState,
} from '../state';

import ContinueButton from '../components/ContinueButton';
import DisclaimerText from '../components/DisclaimerText';
import Form, { useErrorMessages } from '../components/Form';
import InputGroup from '../components/InputGroup';
import OuterWizardContainer from '../components/OuterWizardContainer';
import Wizard from '../components/Wizard';

import EMAIL_REGEX from '../util/email-regex';

// NOTE: after this step, if we even get here, we can continue to sign the docusign information
function BrokerForm() {
  const {
    setVisited,
    setBrokerInfo,
  } = useWizardActions();

  const { siteInfo } = useWizardState();

  const [location, setLocation] = useLocation();

  const { handleSubmit, register, errors } = useForm({
    validateCriteriaMode: 'all',
    nativeValidation: true,
    mode: 'onBlur',
    defaultValues: siteInfo === null ? {} : siteInfo,
  });

  const [submitted, setSubmitted] = useState(null);

  const formSubmit = (d, evt) => {
    evt.preventDefault();

    const formData = {
      full_name: d.full_name,
      email: d.email,
      phone_number: d.phone_number,
    };

    setSubmitted(formData);
  };


  useEffect(() => {
    if (submitted) {
      setBrokerInfo(submitted);
      setVisited(location);
      setLocation('/koc');
    }
  }, [submitted, location]);

  const errorMessages = useErrorMessages({ errors, requiredErrorMessage: 'All fields are required' });

  return (
    <Form onSubmit={handleSubmit(formSubmit)}>
      <h2 css={css`margin-bottom: 1em !important;`}>Broker's Information</h2>

      <DisclaimerText>
        Your MLS board requires your broker-in-charge's contact information for
        the IDX feed paperwork.Please make sure this information is accurate
        and up-to-date.
      </DisclaimerText>

      <InputGroup hasError={errors.full_name}>
        <label>Full Name</label>
        <input name="full_name" type="text" ref={register({
          required: true,
        })}></input>
      </InputGroup>

      <InputGroup hasError={errors.email}>
        <label>Email</label>
        <input name="email" type="text" ref={register({
          required: true,
        })}></input>
      </InputGroup>

      <InputGroup hasError={errors.phone_number}>
        <label>Phone Number</label>
        <input name="phone_number" type="tel" ref={register({
          required: true,
        })}></input>
      </InputGroup>


      {errorMessages}
      <ContinueButton marginY="1em" marginTop="1em" onClick={handleSubmit(formSubmit)}>CONTINUE</ContinueButton>
    </Form>
  );
}

export default function BrokerInformation() {
  return (
    <OuterWizardContainer>
      <Wizard column>
        <BrokerForm />

      </Wizard>
    </OuterWizardContainer>
  );
}
