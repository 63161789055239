import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';

const Loader = styled.div`
  text-align: center;
  margin-bottom: 5rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 3rem 4rem 4rem 4rem;
  width: 30vw;

  img {
    width: 100%;
  }

  h4 {
    font-weight: normal;
  }

  ${mq.sm} {
    width: 60vw;
    max-width: 60vw;
    padding: 2.5rem 1rem 1rem 1rem;
    margin: 2rem 1rem 1rem 1rem;

    h4 {
      margin: 1rem;
    }
  }

  ${mq.xs} {
    width: 80vw;
    max-width: 80vw;
    padding: 2.5rem 1rem 1rem 1rem;
    margin: 2rem 1rem 1rem 1rem;

    h4 {
      margin: 1rem;
    }
  }
`;

export default Loader;
