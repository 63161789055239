import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';

const DEFAULT_PADDING = '3em 4em 4em 4em';
const DEFAULT_MOBILE_PADDING = '2em 1em 2em 1em';

const paddingStyles = (mobile=false) => ({ padding = null }) => `padding: ${padding || (mobile ? DEFAULT_MOBILE_PADDING : DEFAULT_PADDING )};`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 3px 50px #00000029;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  ${paddingStyles()}
  z-index: 1;
  width: 30vw;
  max-width: 40vw;

  ${mq.sm} {
    width: 90vw;
    max-width: 80vw;
    ${paddingStyles(true)}
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
  }
`;

export default Card;
