/** @jsx jsx */
/** @jsxFrag React.Fragment */

import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import {jsx, css} from '@emotion/core';

import {Controller} from 'react-hook-form';
import Select from 'react-select';

import {COUNTRY_OPTIONS, TERRITORY_OPTIONS, ZIP_PATTERNS} from './addressConstants';

export const ZipCodeInput = ({country, name, control, required, ...props}) => {
  const {register} = control;

  const localizedName = useMemo(() => {
    return country.value == "CA" ? "Postal Code" : "Zip Code";
  }, [country]);

  const validator = useMemo(() => {
    return ZIP_PATTERNS[country.value];
  }, [country])

  return (
    <>
      <label htmlFor={name}>{localizedName}</label>
      <input autoComplete="postal-code" name={name} type="text" required pattern={validator.source} ref={
        register({
          required: required,
          pattern: validator,
        })
      }></input>
    </>
  );
};

export const TerritorySelect = ({country, name, control, onChange, required, ...props}) => {
  const {setValue} = control;

  const [territoryOptions, setTerritoryOptions] = useState(TERRITORY_OPTIONS.US);

  const localizedName = useMemo(() => {
    return country.value === "CA" ? "Province" : "State";
  }, [country]);

  useEffect(() => {
    if (country && country.value) {
      setTerritoryOptions(TERRITORY_OPTIONS[country.value]);
      setValue("state", TERRITORY_OPTIONS[country.value][0]);
    }
  }, [country]);

  return (
    <>
      <label htmlFor={name} css={css`z-index: 1;`}>{localizedName}</label>
      <Controller
        as={<Select options={territoryOptions} />}
        control={control}
        rules={{required: required}}
        onChange={([selected]) => {
          onChange(selected);
          return selected;
        }}
        name={name}
        {...props}
      />
    </>
  );
}

