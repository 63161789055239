import { useEffect, useState } from 'react';

import {
  useWizardActions,
  useWizardProps,
  useWizardState,
} from '../../state';

export default function useMLSBrokerCheck() {
  const {
    mlsBrokerInfoRequiredUrl,
  } = useWizardProps();

  const {
    setBrokerInfoRequired,
  } = useWizardActions();

  const {
    mlsBoard,
    requiresBrokerInfo,
  } = useWizardState();

  useEffect(() => {
    if (mlsBoard) {
      let payload = {};

      if (mlsBoard.value !== 'Other') {
        payload = {
          board_id: mlsBoard.value.board_id,
          board_name: mlsBoard.value.name,
        };
      }

      else {
        payload = {
          board_name: mlsBoard.mls_board_name,
        };
      }

      $.post(JSON.parse(mlsBrokerInfoRequiredUrl), JSON.stringify(payload)).done(() => {
      }).fail((res) => {
        if (res.status === 400 && res.responseText === 'Broker name and email are required') {
          setBrokerInfoRequired(true);
        }
      });
    }
  }, [
    mlsBoard,
    mlsBrokerInfoRequiredUrl,
    requiresBrokerInfo,
  ]);
};
