import { useState, useEffect, useCallback } from "react";


// returns the current hash location in a normalized form
// (excluding the leading '#' symbol)
const currentLocation = () => {
  return window.location.hash.replace(/^#/, "") || "/";
};


// NOTE: taken from https://github.com/molefrog/wouter#customizing-the-location-hook
// this utility is how the framework suggests implementing hash based routing
const useHashLocation = () => {
  const [loc, setLoc] = useState(currentLocation());

  useEffect(() => {
    // this function is called whenever the hash changes
    const handler = () => setLoc(currentLocation());

    // subscribe to hash changes
    window.addEventListener("hashchange", handler);
    return () => window.removeEventListener("hashchange", handler);
  }, []);

  // remember to wrap your function with `useCallback` hook
  // a tiny but important optimization
  const navigate = useCallback(to => (window.location.hash = to), []);

  return [loc, navigate];
};


export default useHashLocation;
