import React from 'react';
import ObservedVisitRoute from '../components/ObservedVisitRoute';
import OuterWizardContainer from '../components/OuterWizardContainer';
import Wizard from '../components/Wizard';
import Loader from '../components/Loader';
import { useWizardState } from '../state';

export default function ConfirmThanks() {
  const { promoCode, siteInfo, userDetails } = useWizardState();
  const re = /(?:www.)(.*?)\.(?:.*)/;

  if (typeof promoCode == "string" && promoCode.startsWith("ccode")) {
    return (
      <ObservedVisitRoute>
        <OuterWizardContainer>
          <Wizard>
            <Loader>
              <h1>Thanks for signing up!</h1>
              <br />
              <h4>
                You should receive an email within the next few minutes with your new site information.
                Make sure you check your spam folder if you can't find the message.
              </h4>
            </Loader>
          </Wizard>
        </OuterWizardContainer>
      </ObservedVisitRoute>
    );
  }

  const devSiteDomain = siteInfo.site_domain.match(re)[1];
  const url = `https://${devSiteDomain}.realgeeks.com/admin/wizard/?first_name=${userDetails.firstName}&last_name=${userDetails.lastName}&email=${userDetails.email}&phone=${userDetails.phone_number}&company=${siteInfo.company_name}`;
  document.location = url;
  return (<div>You should be redirected. If not click <a href={url}>here</a>.</div>);
};
