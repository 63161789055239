import React from 'react';
import styled from '@emotion/styled';

import { hideOnMobile } from '../styles/mobile-hide';
import { directionalPosition, directionalMargin } from '../styles/positional';


const StepButton = styled.button`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  ${directionalPosition}
  top: 45%;
  border-radius: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  width: 5em;
  height: 5em;
  border: none;
  text-decoration: none;
  font-size: 1em;
  text-align: center;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  img {
    ${directionalMargin}
    height: 1.5em;
  }

  ${hideOnMobile}
`;


export default StepButton;
