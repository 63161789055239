export default [
  "/",
  "/options",
  "/account",
  "/site",
  "/mls",
  "/cart?event=signing_complete",
  "/koc",
  "/thanks?event=signing_complete",
];
