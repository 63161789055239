/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import Switch from "react-switch";

const CustomSwitch = (props) => (
  <Switch
    onColor="#4592ff"
    offColor="#B2B2B2"
    height={20}
    width={37}
    checkedIcon={false}
    uncheckedIcon={false}
    // checked={true}
    css={css`margin-right: 1em;`}
    {...props}
    />
);

export default CustomSwitch;
