import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';


const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  box-shadow: 0px 3px 50px #00000029;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  text-align: center;
  padding-bottom: 2em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: ${props => props.paddingTop || '7em'};
  z-index: 1;
  width: 30vw;
  position: relative;

  .ribbon-monthly {
    position: absolute;
    top: 1.5em;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.1px;
    display: inline-block;
    width: 100%;

    img {
      position: absolute;
      width: 340px;
      left: -39px;

      ${mq.sm} {
        left: -1.2em;
      }
    }

    .annual-img {
      min-width: 320px;
    }

    .ribbon-cta {
      position: absolute;
      top: 1em;
      left: 4%;
      display: inline-block;

      &.annual {
        color: white;
      }

      ${mq.md} {
        left: 2em;
      }

      ${mq.sm} {
        top: 1.2em;
        font-size: 18px;
      }
    }

  }

  h4 {
    margin-bottom: 3em;
  }

  h1 {
    font-size: 96px;
    letter-spacing: 0.48px;

    text-align: center;

    span {
      font-size: 23px;
      margin-left: 0.3em;
      margin-right: 0.3em;
    }

    ${mq.sm} {
      position: relative;
      top: -0.5em;
      padding-top: 0.5em;
      padding-bottom: 0px;

      font-size: 86px;
      max-width: 100%;
      text-align: center;
    }
  }

  ${mq.sm} {
    padding: 6em 0em 2em 0em;
    margin: 1em;
    margin-bottom: 0px;
    z-index: 1;
    width: 90vw;
    max-width: 90vw;
  }
`;


export default PriceContainer;
