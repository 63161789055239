import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';

const lightColor = ({ light = false }) => light ? '#D7D7D7' : 'black';

const Divider = styled.div`
  width: 100%;
  border-bottom: solid thin ${lightColor};
  height: 0px;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export default Divider;
