import React, {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react';

const BoardOptionContext = createContext();

export default function BoardOptionProvider({ children, boardOptionsUrl }) {
  const [boardOptions, setBoardOptions] = useState(null);

  const state = {
    boardOptions,
    setBoardOptions,
    boardOptionsUrl
  };

  return (
    <BoardOptionContext.Provider value={state}>
      { children }
    </BoardOptionContext.Provider>
  );
}

/**
 * Returns the stored board options as kept within an internal reducer.
 * This used to live in the top level reducer state but it doesn't really make
 * sense to live there since it's not really application level state.
 */
export const useBoardOptionState = () => {
  return useContext(BoardOptionContext);
};

/**
 * Preloads the board options data in the background so as to avoid a slow
 * transition. The component this is called from does not necessarily need to
 * use the board option data, ideally it would precede the location where it
 * will be used so that it is ready.
 */
export const useBoardOptionPreloader = () => {
  const {
    boardOptions,
    setBoardOptions,
    boardOptionsUrl,
  } = useBoardOptionState();

  const shouldLoadBoardOptions = useMemo(
    () => boardOptionsUrl && !boardOptions,
    [boardOptions, boardOptionsUrl],
  );

  useEffect(() => {
    if (shouldLoadBoardOptions && boardOptionsUrl) {
      $.get(JSON.parse(boardOptionsUrl)).done(setBoardOptions);}
  }, [shouldLoadBoardOptions, boardOptionsUrl]);
};

/**
 * Returns the stored board options as initial data for a pair of select boxes,
 * where the state controls which boards are selectable.
 *
 * Returns:
 *   Map<state_name, { name: board_display_name, value: board_id }>
 */
export const useBoardOptions = () => {
  const { boardOptions } = useBoardOptionState();
  const cleanOptions = useMemo(() => boardOptions ? Object.keys(boardOptions).reduce(
    (acc, state) => {
      if (state === 'count') {
        return acc;
      }

      return ({
        ...acc,
        [state]: Object.keys(boardOptions[state]).map(boardId => ({
          label: boardOptions[state][boardId].display_name,
          value: boardOptions[state][boardId],
        }))
      });
    },
    {},
  ) : null, [boardOptions]);

  return cleanOptions;
};


/**
 * Given a board ID as configured for rope and returned by MCP, we search for
 * the board by that ID (since we can't filter for it in bregistry) and return
 * the appropriate board option.
 */
export const useBoardById = (boardId) => {
  const { boardOptions } = useBoardOptionState();
  return useMemo(() => {
    if (!boardOptions) return [null, null];
    if (!boardId) return [null, null];

    for (let state in boardOptions) {
      for (let bregistryId in boardOptions[state]) {
        const board = boardOptions[state][bregistryId];

        if (board.board_id === boardId) {
          return [state, board];
        }
      }
    }

    return [null, null];
  }, [boardOptions, boardId]);
};
