import React, {
  useMemo,
} from 'react';
import Modal from 'react-modal';

import useBreakpointDetector from '../util/use-breakpoint-detector';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 50px #00000029',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    border: '0px',
    alignItems: 'center',
  },
  overlay: {
    zIndex: '2',
  }
};

export default function LoadingModal({ isOpen, onAfterOpen, onClose, children }) {
  const isMobile = useBreakpointDetector('sm');

  const modalStyles = useMemo(() => {
    if (isMobile) {
      return {
        ...customStyles,
        content: {
          ...customStyles.content,
          width: '80%',
          height: '50%',
          padding: '2em 1.5em',
          justifyContent: 'space-evenly',
          textAlign: 'center',
        },
      };
    }

    return customStyles;
  }, [isMobile]);

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      style={modalStyles}
      contentLabel="Loading"
    >
      { children }
    </Modal>
  );
}
