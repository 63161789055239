/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { css, jsx } from '@emotion/core';
import React, {
  useEffect,
  useState,
} from 'react';

import styled from '@emotion/styled';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { useLocation } from 'wouter';

import {
  useWizardActions,
  useWizardState,
  useWizardProps,
  useBoardById,
} from '../state';

import ClearButton from '../components/ClearButton';
import ContinueButton from '../components/ContinueButton';
import DisclaimerText from '../components/DisclaimerText';
import Form, { useErrorMessages } from '../components/Form';
import InputGroup from '../components/InputGroup';
import ObservedVisitRoute from '../components/ObservedVisitRoute';
import OuterWizardContainer from '../components/OuterWizardContainer';
import Wizard from '../components/Wizard';
import StepIndicators from '../components/StepIndicators';

const DomainMatcher = /^www\.(?!tbd|example|realgeeks|test)[a-zA-Z0-9\_\-]+\.[a-zA-Z]+$/im;
const CompanyMatcher = /^(?!^[\s]+$|$)[\x00-\x7F]+$/

const FootnoteValid = styled.p`
  color: #53B29A;
  padding: 0 0 0.5em 0.25em;
  display: block;
`;

const FootnoteWarning = styled.p`
  color: #FEA500;
  padding: 0 0 0.5em 0.25em;
  display: block;
`;

const Input = styled.input`
  width: 21px;
  height: 21px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 1em 0 0.25em;
  padding: 3.5px;
`;

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #787878;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block;
  appearance: none;
  margin-top: 4px;
  margin-right: 1em;
  min-width: 16px;

  &:active, &:checked:active {
    background-color: #15C639;
    border-color: #15C639;
  }

  &:checked {
    background-color: #15C639;
    border-color: #15C639;
  }

  &:checked:after {
    content: "\\2713";
    font-weight: 900;
    font-size: 0.9em;
    color: white;
    position: relative;
    left: 15%;
    background-color: #15C639;
    border-color: #15C639;
  }
`;


const FlexLabel = styled.label`
  color: black;
  display: block;
  vertical-align: middle;
`

const StateLabel = styled.label`
  z-index: 1;
`;

const TextArea = styled.textarea`
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  width: 100%;
  max-width: 100%;
  max-height: 70%;
  resize: none;
`;

const TIMEZONE_OPTIONS = [
  {
    value: 'Central Time (US & Canada)',
    label: 'Central Time (US & Canada)',
  },
  {
    value: 'Hawaii',
    label: 'Hawaii',
  },
  {
    value: 'Pacific Time (US & Canada)',
    label: 'Pacific Time (US & Canada)',
  },
  {
    value: 'Mountain Time (US & Canada)',
    label: 'Mountain Time (US & Canada)',
  },
  {
    value: 'Eastern Time (US & Canada)',
    label: 'Eastern Time (US & Canada)',
  },
  {
    value: 'Indiana (East)',
    label: 'Indiana (East)',
  },
  {
    value: 'Arizona',
    label: 'Arizona',
  },
  {
    value: 'Alaska',
    label: 'Alaska',
  },
];


const selectStyles = {
  input: styles => ({
    ...styles,
    padding: '1em',
  }),
  placeholder: styles => ({
    ...styles,
    paddingLeft: '1em',
  }),
  option: styles => ({
    ...styles,
    paddingLeft: '1.5em',
  }),
  singleValue: styles => ({
    ...styles,
    paddingLeft: '1em',
  }),
  menu: styles => ({
    ...styles,
    zIndex: '2',
  }),
};
const useDomainAvailability = ({watch, setError}) => {
  const { domainPurchase } = useWizardState();
  const { setDomainPurchase } = useWizardActions();
  const { domainAvailableUrl } = useWizardProps();

  const [domainAvailable, setDomainAvailable] = useState(null);
  const site_domain = watch('site_domain');

  useEffect(() => {
    let match = site_domain ? site_domain.match(DomainMatcher) : null
    if (match !== null) {
      const timeoutHandle = setTimeout(() => {
        $.get(
          JSON.parse(domainAvailableUrl),
          { site_domain },
        ).done((data, status, response) => {
          if (response.status === 200 && 'available' in data) {
            setDomainAvailable(data.available);
          }
        }).fail((data) => {
          setError("site_domain", "validate", "Domain Unavailable");
          console.error(data)
        });
      }, 750);

      return () => {
        clearTimeout(timeoutHandle);
        setDomainAvailable(null);
      };
    }
    return () => {
      setDomainAvailable(null)
    };

  }, [site_domain, setError, domainAvailableUrl]);

  return [domainAvailable,]
}

const useDomainReactivationBoundary = ({ watch, setError }) => {
  const { userDetails } = useWizardState();
  const { ownDomainCheckUrl } = useWizardProps();
  const {
    setBoard,
    setBoardState,
  } = useWizardActions();

  const { email } = userDetails;
  const domain = watch('site_domain');

  const [oldDomain, setOwnsDomain] = useState(false);
  const [existingDomainBoards, setExistingDomainBoards] = useState([]);

  useEffect(() => {
    if (domain && domain.match(DomainMatcher)) {
      const timeoutHandle = setTimeout(() => {
        $.get(
          JSON.parse(ownDomainCheckUrl),
          { email, domain },
        ).done((d, _text, res) => {
          if (res.status === 200) {
            setExistingDomainBoards(d.boards);
            setOwnsDomain(true);
          }
        }).fail(() => {
          setOwnsDomain(false);
        });
      }, 750);

      return () => {
        clearTimeout(timeoutHandle);
        setOwnsDomain(false);
      };
    }

    return () => {};
  }, [email, domain, setError, ownDomainCheckUrl]);

  const [targetBoardId, setTargetBoardId] = useState(null);

  useEffect(() => {
    if (oldDomain && existingDomainBoards) {
      setTargetBoardId(existingDomainBoards[0]);
    }
  }, [oldDomain, existingDomainBoards]);

  const [state, board] = useBoardById(targetBoardId);

  useEffect(() => {
    if (state && board) {
      setBoard({
        value: board,
        label: board.display_name,
      });

      setBoardState({
        value: state,
        label: state,
      });
    }
  }, [state, board]);

  return [oldDomain, { state, board }];
};


function DetailsForm() {
  const { validSiteUrl } = useWizardProps();
  const {
    nextStep,
    previousStep,
    setVisited,
    setSiteInfo,
    setDomainPurchase,
  } = useWizardActions();

  const { siteInfo } = useWizardState();

  const [location,] = useLocation();

  const { handleSubmit, register, unregister, errors, watch, setValue, setError, clearError } = useForm({
    validateCriteriaMode: 'all',
    nativeValidation: true,
    mode: 'onBlur',
    defaultValues: siteInfo === null ? {} : siteInfo,
  });

  const [timezone, setTimezone] = useState(TIMEZONE_OPTIONS[0]);
  const [submitted, setSubmitted] = useState(null);

  const [oldDomain,] = useDomainReactivationBoundary({ watch, setError });
  const [domainAvailable,] = useDomainAvailability({ watch, setError });

  useEffect(() => {
    if (oldDomain) {
      setError('site_domain', 'validate', 'Looks like this is your old domain. We will reactivate this domain for you.');
    }

    return () => { };
  }, [oldDomain, setError, clearError]);

  const onTimezoneChange = (option) => {
    setTimezone(option);
  };

  const validateDomain = (site_domain) => new Promise((resolve, reject) => {
    if (oldDomain) {
      resolve(true);
    }
    else if (site_domain && !site_domain.match(DomainMatcher)) {
      resolve(false);
    }
    else {
      $.get(JSON.parse(validSiteUrl), { 'site_domain': site_domain })
        .done(() => resolve(true))
        .fail(resp => {
          resolve(resp.responseJSON.message);
        });
    }
  });

  const validateCompanyName = (company_name) => {
    if (company_name.indexOf('@') >= 0) {
      return 'The company name can not include any \'@\' characters';
    }
  };

  const formSubmit = (d, evt) => {
    const formData = {
      site_domain: d.site_domain.toLowerCase(),
      company_name: d.company_name,
      additional_notes: d.additional_notes,
      domain_confirm: d.domain_confirm,
      timezone,
    };

    setSubmitted(formData);
  };

  useEffect(() => {
    if (submitted) {
      setSiteInfo(submitted);
      setDomainPurchase(domainAvailable);
      setVisited(location);
      nextStep();
    }
  }, [submitted, location, domainAvailable]);

  const requiredErrorMessages = useErrorMessages({ errors, requiredErrorMessage: 'Missing required field!' });

  const handleUserKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(formSubmit)();
    }
  };

  return (
    <Form onSubmit={handleSubmit(formSubmit)}>
      <ClearButton onClick={previousStep}>
        <img src="/static/images/BackArrowGrey.svg" />
        <span>Back</span>
      </ClearButton>

      <h2 css={css`margin-bottom: 1em !important;`}>Website Information</h2>

      <DisclaimerText>
        Enter a domain name for your Real Geeks website. If you don't own a domain, we will buy one for you. If you already own a domain, enter it below. Your domain can always be changed later.
      </DisclaimerText>

      <InputGroup hasError={errors.site_domain && !errors.site_domain.isManual}>
        <label>Website URL</label>
        <input name="site_domain" type="text" placeholder="www.example.com" onKeyPress={handleUserKeyPress} ref={register({
          required: true,
          validate: validateDomain,
          pattern: DomainMatcher,
        })}></input>

        {domainAvailable ?
            (<FootnoteValid>This domain is available.</FootnoteValid>) :
            (null)
        }
      </InputGroup>

      { domainAvailable === false ? (
        <InputGroup styleLabel={false} styleInput={false} noMargin={true} css={css`margin: -0.5em 1em 1em 1em;`}>
          <FootnoteWarning>If you own this domain, please continue. <br />Otherwise, please choose a different domain.</FootnoteWarning>
          <FlexLabel>
            <CheckBox type="checkbox" name="domain_confirm" ref={register({required:!domainAvailable})} />
            I already own this domain
          </FlexLabel>
        </InputGroup>) : (<></>)
      }

      <InputGroup hasError={errors.company_name}>
        <label>Company Name</label>
        <input name="company_name" required pattern={CompanyMatcher} type="text" placeholder="Example Company" onKeyPress={handleUserKeyPress} ref={register({
          required: true,
          validate: validateCompanyName,
          pattern: CompanyMatcher,
        })}></input>
      </InputGroup>

      <InputGroup hasError={errors.timezone}>
        <StateLabel>Timezone</StateLabel>
        <Select name="timezone" options={TIMEZONE_OPTIONS} styles={selectStyles} onChange={onTimezoneChange} defaultValue={(siteInfo && siteInfo.timezone) ? siteInfo.timezone : timezone} />
      </InputGroup>

      <InputGroup hasError={errors.additional_notes}>
        <label>Additional Notes (Optional)</label>
        <TextArea name="additional_notes" onKeyPress={handleUserKeyPress} ref={register({
          required: false,
        })}></TextArea>
      </InputGroup>

      {requiredErrorMessages}

      <ContinueButton css={css`margin-bottom: 1em;`} marginY='1em' marginTop="1em" onClick={handleSubmit(formSubmit)}>CONTINUE</ContinueButton>
    </Form>
  );
}


export default function WebsiteInfo() {
  return (
    <ObservedVisitRoute>
      <OuterWizardContainer>
        <Wizard column>
          <DetailsForm />

          <StepIndicators firstStep={2} />
        </Wizard>
      </OuterWizardContainer>
    </ObservedVisitRoute>
  );
};
