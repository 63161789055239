import React from "react";

import ContinueButton from "./ContinueButton";
import PriceContainer from "./PriceContainer";
import { useWizardActions, useWizardState, useWizardProps } from "../state";
import { getPackageAddonPrice, getPackageAddonMandatory } from '../state/packages';

const PackagePriceContainer = ({ packageName, price }) => {
  const { currentPrice, billingPlan } = useWizardState();
  const { nextStep, setBilling, setGeekAi, setSoloTrainingSessions } = useWizardActions();
  const {
    addons: {
      geek_ai,
      solo_training,
    },
  } = useWizardState();

  const { packages } = useWizardProps();
  const { commitment, addons } = useWizardState();

  const packageDisplayName = packages[packageName].display;
  const packageDescription = packages[packageName].description;

  const handleClick = () => {
    const p = packageName.toLowerCase()

    setBilling(p, packages);

    const geekAiPrice = getPackageAddonPrice(packages, p, 'geek_ai');
    if (geekAiPrice === 0 && geek_ai === false) {setGeekAi(true, packages);}

    const soloTrainingSessionsMandatory = getPackageAddonMandatory(packages, p, 'solo_training');
    if (soloTrainingSessionsMandatory && solo_training === false) {setSoloTrainingSessions(true, packages);}

    nextStep();
  };

  return (
    <PriceContainer paddingTop="2em">
      <div style={{ fontSize: "40px", fontWeight: "bold" }}>
        {packageDisplayName ? packageDisplayName : billingPlan}
      </div>
      <div style={{ color: "#667085", fontSize: "18px", marginTop: "1em", marginBottom: "1em" }}>
        { packageDescription }
      </div>
      <div style={{ fontSize: "40px", fontWeight: "bold", marginBottom: "0.5em" }}>
        <span>$</span>{price ? price : currentPrice}<span style={{ fontSize: "18px" }}> / month</span>
      </div>


      <ContinueButton onClick={handleClick} marginBottom={"0"}>
        SELECT
      </ContinueButton>
    </PriceContainer>
  );
}

export default PackagePriceContainer;
