export const createPositionalStyle = (lprop, rprop, value) => ({
  left = false,
  right = false,
}) => {
  if (left) {
    return `${lprop}: ${value};`;
  }

  else if (right) {
    return `${rprop}: ${value};`;
  }

  return '';
};


export const directionalPosition = createPositionalStyle('left', 'right', '-4%');
export const directionalMargin = createPositionalStyle('margin-right', 'margin-left', '2em');
