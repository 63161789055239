import React, {
  useCallback,
  useState,
  useReducer,
  useEffect,
  useMemo,
} from 'react';
import { useLocation } from 'wouter';

import { useWizardProps, useWizardState, useWizardActions } from '../state';
import OuterWizardContainer from '../components/OuterWizardContainer';
import Wizard from '../components/Wizard';
import Loader from '../components/Loader';


const useMlsPaperworkPoller = () => {
  const [, setLocation] = useLocation();
  const {
    mlsPaperworkUrl,
    siteCreationStatusUrl,
  } = useWizardProps();
  const {
    userDetails: { firstName, lastName, email, password },
    mlsBoard,
    mlsBrokerInfo,
    siteInfo,
    domainPurchase,
  } = useWizardState();

  const [finalRedirectUrl, setFinalRedirectUrl] = useState(null);
  const [pollAttempts, incrementPollAttempts] = useReducer((s, a) => a ? a : s + 1, 0);

  const paperworkPayload = useMemo(() => {
    const payload = {
      email,
      first_name: firstName,
      last_name: lastName,

      board_id: mlsBoard.value.board_id,
      board_name: mlsBoard.value.name,
      domain: siteInfo.site_domain,
    };

    if (password) {
      payload.password = password;
    }

    if (mlsBrokerInfo) {
      payload.broker_name = mlsBrokerInfo.full_name;
      payload.broker_email = mlsBrokerInfo.email;
    }

    return payload;
  }, [
    firstName,
    lastName,
    password,
    email,
    mlsBoard,
    siteInfo,
  ]);

  const getAndHandlePaperwork = useCallback(() => {
    $.post(mlsPaperworkUrl, JSON.stringify(paperworkPayload)).done(({ paperwork }) => {
      setFinalRedirectUrl(paperwork);
    }).fail((res) => {
      incrementPollAttempts(60);
    });
  }, [
    mlsPaperworkUrl,
    paperworkPayload,
    incrementPollAttempts,
  ]);

  const getSiteCreationStatus = useCallback(() => {
    $.get(`${siteCreationStatusUrl}?task=create_site&key=${siteInfo.site_domain}`).done((res) => {
      if (res.status === 'FINISHED') {
        getAndHandlePaperwork();
      }

      else {
        incrementPollAttempts();
      }
    }).fail((res) => {
      // TODO: the status code here might indicate we need to log in?
      // NOTE: I've removed the log in error, so in this case we should probably
      // just follow up if something dies horribly. I think this will also solve
      // some cases of the returning client flow.
      incrementPollAttempts(60);
    });
  }, [
    siteCreationStatusUrl,
    getAndHandlePaperwork,
    paperworkPayload,
    incrementPollAttempts,
  ]);

  useEffect(() => {
    if (pollAttempts < 60 && siteCreationStatusUrl && !finalRedirectUrl) {
      setTimeout(() => {
        getSiteCreationStatus();
      }, 3000);
    }
  }, [
    paperworkPayload,
    mlsPaperworkUrl,
    siteCreationStatusUrl,
    getAndHandlePaperwork,
    finalRedirectUrl,
    pollAttempts,
  ]);

  useEffect(() => {
    if (pollAttempts >= 60 && !finalRedirectUrl) {
      setLocation('/thanks');
    }

    else if (finalRedirectUrl) {
      window.location.href = finalRedirectUrl;
    }
  }, [finalRedirectUrl, pollAttempts]);
};

const CreatingSite = () => {
  useMlsPaperworkPoller();

  return (
    <OuterWizardContainer>
      <Wizard>
        <Loader>
          <h1>Please Wait</h1>
          <br />
          <h4>We are setting up your site...</h4>
          <h4>If you aren't automatically redirected to your site, <a href="/thanks">click here</a>.</h4>
        </Loader>
      </Wizard>
    </OuterWizardContainer>
  );
};

export default CreatingSite;
