export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const getCsrfToken = () => getCookie('csrftoken');

const parseSnekValue = (rawValue) => {
  if (!rawValue) {
    return null;
  }

  const cleanValue = rawValue.trim();
  if (cleanValue === 'None') {
    return null;
  }

  return cleanValue;
};

const getDataParser = mount => label => parseSnekValue(JSON.stringify(mount.data(label)));


export const mount = $('#mount');
export const getData = getDataParser(mount);
