import styled from '@emotion/styled';
import { mq } from '../styles/breakpoints';


const CommitmentButton = styled.button`
  margin: 0.5em;
  background: none;
  border-radius: 6px;
  padding: 10px 25px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: none;
  cursor: pointer;

  ${({ selected }) => selected && 'background-color: white;'}
  ${({ selected }) => selected && 'color: #4592ff;'}
`;


export default CommitmentButton;
