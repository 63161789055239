import { useState, useEffect } from 'react';

import useWindowSize from './use-window-size';
import { breakpoints } from '../styles/breakpoints';

const useBreakpointDetector = (bp, dimension='width') => {
  const windowSize = useWindowSize();
  const [matchesBreakpoint, setMatchesBreakpoint] = useState(false);

  useEffect(() => {
    if (windowSize && windowSize[dimension]) {
      setMatchesBreakpoint(windowSize[dimension] <= breakpoints[bp]);
    }
  }, [windowSize]);

  return matchesBreakpoint;
};

export default useBreakpointDetector;
