/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';

import {
  useWizardActions,
  useWizardState,
} from '../../state';

import { mq } from '../../styles/breakpoints';
import CustomSwitch from '../../components/CustomSwitch';

const toggleContainerStyles = css`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;

  li {
    display: flex;
    align-content: center;
    align-items: center;

    padding-bottom: 2em !important;


    img {
      width: 2em;
      max-height: 2em;
    }

    p {
      font-weight: medium;
      font-size: 1.2em;
      display: inline-block;
      padding-left: 1em;
    }

    ${mq.sm} {
      padding-bottom: 1.5em !important;

      img {
        width: 1.2em;
        max-height: 1.2em;
      }

      p {
        font-size: 1em;
      }
    }

    &:last-child {
      padding-bottom: 0px !important;
    }
  }

`;


export default function ManagedMarketing() {
  const { toggleGoogle, toggleFb, toggleTrafficBlaster } = useWizardActions();
  const {
    addons: {
      google,
      fb,
      traffic_blaster,
    },
  } = useWizardState();

  return (
    <React.Fragment>
      <h2>Want us to manage your marketing?</h2>
      <p css={css`margin-top: 0.3em;`}>Includes management fee and minimum budget.</p>
      <div className="switch-container">
        <ul css={toggleContainerStyles}>
          <li>
            <CustomSwitch disabled={google} checked={traffic_blaster} onChange={toggleTrafficBlaster} />
            <img src="/static/images/rocket-solid.svg"/>
            <p>Traffic Blaster ($300/mo)</p>
          </li>
          <li>
            <CustomSwitch disabled={traffic_blaster} checked={google} onChange={toggleGoogle} />
            <img src="/static/images/google.svg"/>
            <p>Google PPC (From $600/mo)</p>
          </li>
          <li>
            <CustomSwitch checked={fb} onChange={toggleFb} />
            <img src="/static/images/facebook-f.svg"/>
            <p>Facebook Marketing (From $500/mo)</p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};
