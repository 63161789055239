import React, {
  useMemo,
  useEffect,
  useState,
} from 'react';

import { useLocation } from 'wouter';
import { animateScroll as scroll } from 'react-scroll';

import {
  useWizardActions,
  useWizardState,
} from '../state';

import routes from '../steps/routes';

/**
 * Ensures that we can't move ahead of ourselves in the wizard order (for
 * instance, by appending a path for a later step in the wizard). Routing to a
 * previous step is fine.
 */
const ObservedVisitRoute = ({ children }) => {
  const [location, setLocation] = useLocation();
  const [canRender, setCanRender] = useState(false);

  const {
    visited,
    currentLocation
  } = useWizardState();

  const {
    setCurrentLocation,
    invalidateVisitedBeforeTOS,
  } = useWizardActions();

  useEffect(() => {
    scroll.scrollTo(100);

    const locationIndex = routes.indexOf(location);
    const previousLocationIndex = routes.indexOf(currentLocation);
    const cartIndex = routes.indexOf('/cart?event=signing_complete');
    const mlsIndex = routes.indexOf('/mls');

    const didSubmitOrderOnCart = visited['/cart?event=signing_complete'];
    const didMoveBackwards = locationIndex < previousLocationIndex;
    const didComeFromCartOrAfter = previousLocationIndex >= cartIndex;
    const didMoveBeforeCart = locationIndex <= cartIndex;
    const didMoveBeforeMls = locationIndex <= mlsIndex;

    let lastVisitedRouteIndex = routes.reduce((last, route, index) => {
      if (visited[route]) {
        return index;
      }

      return last;
    }, 0);

     // HACK: If hitting the cart after coming from the MLS details step,
    // force visited on those to true. This is to ensure that we don't show
    // the TOS more than once.
    // Temporary fix for https://app.shortcut.com/realgeekshq/story/14279
    const cameFromMLS = previousLocationIndex === 4
    const currentlyOnCart = window.location.hash === "#/cart?event=signing_complete"

    if (cameFromMLS && currentlyOnCart) {
      lastVisitedRouteIndex = 4
    }

    // console.dir({
    //   locationIndex,
    //   previousLocationIndex,
    //   cartIndex,
    //   mlsIndex,
    //   didSubmitOrderOnCart,
    //   didMoveBackwards,
    //   didComeFromCartOrAfter,
    //   didMoveBeforeCart,
    //   didMoveBeforeMls,
    //   lastVisitedRouteIndex,
    //   visited,
    // }, { depth: null });
    // END HACK

    /// AFTER AN ORDER IS MADE, DON'T ALLOW GOING BACK

    // Once you've completed your order through the cart, you can only route to
    // positions after the cart in the list
    // redirect back to the previous location
    if (didMoveBeforeCart && didSubmitOrderOnCart) {
      const nextPossibleRoute = routes[lastVisitedRouteIndex + 1];
      setLocation(nextPossibleRoute);
    }

    /// MAKE SURE WE SIGN TOS BEFORE MAKING AN ORDER

    // If we get to the cart and move backwards in the step order, then we need to
    // update the visited log so that we HAVE to confirm the mls details and load
    // the terms of services, since the terms of services require that all
    // information prior is up to date before generating them

    if (
      didMoveBackwards &&
      didComeFromCartOrAfter &&
        !didSubmitOrderOnCart
    ) {
      invalidateVisitedBeforeTOS();
    }

    /// REROUTING SO YOU CANT SKIP AHEAD

    // Check that we could even possibly route here by checking the visitation log
    if (
        (lastVisitedRouteIndex <= locationIndex) &&
        ((locationIndex - lastVisitedRouteIndex) > 1)
    ) {
      if (lastVisitedRouteIndex === 0) {
        const nextPossibleRoute = routes[lastVisitedRouteIndex];
        setLocation(nextPossibleRoute);
      }
      else {
        const nextPossibleRoute = routes[lastVisitedRouteIndex + 1];
        setLocation(nextPossibleRoute);
      }
    }

    // prevent rendering the next step before we get there
    setTimeout(() => setCanRender(true));

    setCurrentLocation(location);
  }, []);

  return (
    <React.Fragment>
      { canRender && children }
    </React.Fragment>
  );
};

export default ObservedVisitRoute;
