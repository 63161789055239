/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";

import CustomSwitch from "../components/CustomSwitch";
import DetailsContainer from "../components/DetailsContainer";
import ObservedVisitRoute from "../components/ObservedVisitRoute";
import OuterWizardContainer from "../components/OuterWizardContainer";
import PackagePriceContainer from "../components/PackagePriceContainer";
import Wizard from "../components/Wizard";
import { useWizardState } from "../state";
import { mq } from "../styles/breakpoints";
import { InitHeader } from "./headers";

const toggleContainerStyles = css`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;

  li {
    display: flex;
    align-content: center;
    align-items: center;

    padding-bottom: 2em !important;

    img {
      width: 2em;
      max-height: 2em;
    }

    p {
      font-weight: medium;
      font-size: 1.2em;
      display: inline-block;
      padding-left: 1em;
    }

    ${mq.sm} {
      padding-bottom: 1.5em !important;

      img {
        width: 1.2em;
        max-height: 1.2em;
      }

      p {
        font-size: 1em;
      }
    }

    &:last-child {
      padding-bottom: 0px !important;
    }
  }
`;

const OptionsDetails = () => {
  return (
    <React.Fragment>
      <h2>Optional Upgrades from our Preferred Vendors</h2>
      <div className="switch-container">
        <ul css={toggleContainerStyles}>
          <li>
            <CustomSwitch disabled={false} checked={false} />
            <img src="/static/images/rocket-solid.svg" />
            <p>Essentials - $250</p>
          </li>
          <li>
            <CustomSwitch disabled={false} checked={false} />
            <img src="/static/images/rocket-solid.svg" />
            <p>Essentials + - $595</p>
          </li>
          <li>
            <CustomSwitch
              disabled={false}
              checked={false}
              onChange={() => console.log("A")}
            />
            <img src="/static/images/rocket-solid.svg" />
            <p>Custom Homepage - $995</p>
          </li>
          <li>
            <CustomSwitch
              disabled={false}
              checked={false}
              onChange={() => console.log("A")}
            />
            <img src="/static/images/rocket-solid.svg" />
            <p>Seller Site - $250</p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

const WebsiteUpgrades = (_props) => {
  const { billingPlan } = useWizardState();

  return (
    <ObservedVisitRoute>
      <InitHeader />

      <OuterWizardContainer>
        <Wizard column={false}>
          <PackagePriceContainer packageName={billingPlan} />

          <DetailsContainer style={{ height: "auto" }}>
            <OptionsDetails />
          </DetailsContainer>
        </Wizard>
      </OuterWizardContainer>
    </ObservedVisitRoute>
  );
};

export default WebsiteUpgrades;
