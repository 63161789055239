import React from 'react';
import { Redirect, Route, Router } from "wouter";
import makeMatcher from "wouter/matcher";

import {
  WizardProvider,
} from './state';

import AccountDetails from './steps/AccountDetails';
import BoardDetails from './steps/BoardDetails';
import BrokerInformation from './steps/BrokerInformation';
import ConfirmThanks from './steps/ConfirmThanks';
import CreatingSite from './steps/CreatingSite';
import PackagePicker from './steps/PackagePicker';
import PricingInfo from './steps/PricingInfo';
import ScheduleCall from './steps/ScheduleCall';
import ShoppingCart from './steps/ShoppingCart';
import WebsiteInfo from './steps/WebsiteInfo';

import { useWizardProps } from './state';
import OptionsPicker from './steps/OptionsPicker';
import WebsiteUpgrades from './steps/WebsiteUpgrades';
import useHashLocation from './util/use-hash-location';


const defaultMatcher = makeMatcher();

/*
 * A custom routing matcher function that supports multipath routes
 */
const multipathMatcher = (patterns, path) => {
  for (let pattern of [patterns].flat()) {
    const [match, params] = defaultMatcher(pattern, path);
    if (match) return [match, params];
  }

  return [false, null];
};

function NewSiteWizard() {
  return (
    <Router hook={useHashLocation} matcher={multipathMatcher}>
      <Route path='/'>
        <PackagePicker />
      </Route>

      <Route path='/options'>
        <OptionsPicker />
      </Route>

      <Route path="/account">
        <AccountDetails />
      </Route>

      <Route path="/mls">
        <BoardDetails />
      </Route>

      <Route path="/site">
        <WebsiteInfo />
      </Route>

      <Route path="/cart?event=signing_complete">
        <ShoppingCart />
      </Route>

      <Route path="/broker">
        <BrokerInformation />
      </Route>

      <Route path="/koc">
        <ScheduleCall />
      </Route>

      <Route path="/creating">
        <CreatingSite />
      </Route>

      <Route path="/thanks?event=signing_complete">
        <ConfirmThanks />
      </Route>

      <Route path="/thanks">
        <ConfirmThanks />
      </Route>
    </Router>
  );
};


function App(props) {
  return (
    <WizardProvider {...props}>
      <NewSiteWizard />
    </WizardProvider>
  );
};


export default App;
